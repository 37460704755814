import React from 'react';

function useAnimateValue(value, duration) {
  const [current, setCurrent] = React.useState(value);

  React.useLayoutEffect(() => {
    const stepDuration = 1/50;
    const totalSteps = duration / stepDuration;
    const stepSize = (value - current) / totalSteps;
    let currentStep = 0;

    const interval = setInterval(() => setCurrent(current => {
      currentStep++;
      if (currentStep >= totalSteps) {
        clearInterval(interval);
        return value;
      }
      return current + stepSize;
    }), stepDuration * 1000);

    return () => clearInterval(interval);
  }, [value, duration]);

  return current;
}

const AnimatePercentage = React.memo(({ value, duration }) => {
  const v = useAnimateValue(value, duration);
  return Math.round(v * 100) / 100;
});

const ProgressCircle = ({ 
  percentage = 0,
  strokeWidth = 35,
  ballStrokeWidth = 0,
  reduction = 0,
  transitionDuration = 0.5,
  background = '#6A97F0',
  hideBall = true,
  gradient = [
    { stop: 0.6, color: '#0058F0' },
    { stop: 1, color: '#001100' }
  ],
  titleText = null
}) => {
  const width = 200;
  const center = width / 2;
  const height = center + center * Math.cos(reduction * Math.PI);
  const [unique] = React.useState(() => Math.random().toString());
  const rotate = -90 + (180 * reduction);
  const r = center - strokeWidth / 2 - (ballStrokeWidth / 2);
  const circumference = Math.PI * r * 2;
  const offset = circumference * (100 - percentage * (1 - reduction)) / 100;

  return (
    <div className="position-relative w-100 h-100 d-flex align-items-center justify-content-center">
      <svg 
        viewBox={`0 0 ${width} ${height}`} 
        className="position-relative w-100 h-100"
      >
        <defs>
          <linearGradient 
            id={`gradient${unique}`} 
            x1="0%" 
            y1="0%" 
            x2="100%" 
            y2="0%"
          >
            {gradient.map(({stop, color}) => (
              <stop key={stop} offset={`${stop * 100}%`} stopColor={color} />
            ))}
          </linearGradient>
        </defs>
        
        <text x={center} y={center + (titleText ? 0 : strokeWidth/2)} textAnchor="middle" fontSize="30">
          <AnimatePercentage value={percentage} duration={transitionDuration} />%
        </text>
        {titleText && <text x={center} y={center + 22} textAnchor="middle">{titleText}</text>}
        
        {/* Background circle */}
        <circle
          transform={`rotate(${rotate} ${center} ${center})`}
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference * reduction}
          fill="none"
          stroke={background}
          strokeLinecap="round"
          opacity={0.5}
        />
        
        {/* Progress circle */}
        <circle
          style={{
            transition: `stroke-dashoffset ${transitionDuration}s ease`
          }}
          transform={`rotate(${rotate} ${center} ${center})`}
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          fill="none"
          stroke={`url(#gradient${unique})`}
          strokeLinecap="round"
        />
        
        {/* Ball at the end of progress */}
        {!hideBall && (
          <circle
            style={{
              transition: `stroke-dashoffset ${transitionDuration}s ease`
            }}
            transform={`rotate(${rotate} ${center} ${center})`}
            cx={center}
            cy={center}
            r={r}
            strokeWidth={ballStrokeWidth}
            strokeDasharray={`1 ${circumference}`}
            strokeDashoffset={offset}
            fill="none"
            stroke={`url(#gradient${unique})`}
            strokeLinecap="round"
          />
        )}
      </svg>
    </div>
  );
};

export default React.memo(ProgressCircle); 