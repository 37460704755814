import React from 'react'
import classNames from 'classnames'
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import { Toaster } from 'react-hot-toast';

import { AppType } from './types'
import List from './features/completions/List'
import Footer from './features/completions/Footer'
import Channel from './features/completions/Channel'

const SIGN_IN_PATH = "/users/sign_in"

interface Props {
  appType: AppType;
  authenticated: boolean;
}

function PilotApp({ appType, authenticated }: Props) {
  const containerClassNames = classNames({
    "pilot-app": true,
    "pilot-app-card": AppType.Card === appType,
    "unauthenticated": !authenticated,
  })

  if (false === authenticated) {
    return (
      <Card className={containerClassNames}>
        <div className="unauthenticated-overlay">
          <Card>
            <Card.Body>
              <p>Solo Pilot is free to use but requires you to have an account.</p>
              <Button variant="primary" onClick={() => window.location.href = SIGN_IN_PATH}>
                Sign In to Get Started
              </Button>
            </Card.Body>
          </Card>
        </div >
      </Card >
    )
  } else {
    return (
      <>
        <Toaster position="top-center" />
        <Card className={containerClassNames}>
          <Channel>
            <List />
          </Channel>
          <Footer />
        </Card>
      </>
    )
  }
}

export default PilotApp
