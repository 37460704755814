import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = ({ horizontal = false }) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const links = [
    { name: 'Accounts', path: '/collectors/accounts' },
    { name: 'Direct Deposit', path: '/collectors/direct_deposit' },
    { name: 'Payment Details', path: '/collectors/payment_details' }
  ];

  const handleLogout = async () => {
    try {
      const response = await fetch('/collectors/api/logout', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      });

      if (response.ok) {
        navigate('/collectors');
      } else {
        setError('Logout failed. Please try again.');
        setTimeout(() => setError(null), 5000); // Clear error after 5 seconds
      }
    } catch (error) {
      setError('Network error during logout. Please try again.');
      setTimeout(() => setError(null), 5000); // Clear error after 5 seconds
    }
  };

  const isCurrentPage = (path) => {
    return window.location.pathname === path;
  };

  const renderError = () => {
    if (!error) return null;
    
    return (
      <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1050,
        minWidth: '300px'
      }}>
        {error}
        <button type="button" className="btn-close" onClick={() => setError(null)} aria-label="Close"></button>
      </div>
    );
  };

  if (horizontal) {
    return (
      <>
        {renderError()}
        <div className="d-flex mb-4">
          <ul className="nav nav-tabs">
            {links.map((link) => (
              <li className="nav-item" key={link.name}>
                <a 
                  href={link.path}
                  className={`nav-link ${isCurrentPage(link.path) ? 'active' : ''}`}
                >
                  {link.name}
                </a>
              </li>
            ))}
            <li className="nav-item">
              <button 
                onClick={handleLogout}
                className="nav-link text-danger"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }

  return (
    <div className="col-md-auto mb-10">
      {renderError()}
      <div className="card rounded-top-start-3 rounded-bottom-end-3">
        <div className="list-group list-group-flush pt-8 pb-12">
          {links.map((link) => (
            <Link
              key={link.name}
              to={link.path}
              className={`list-group-item list-group-item-action border-0 px-4 py-2 ${
                isCurrentPage(link.path) ? 'text-primary' : ''
              }`}
            >
              {link.name}
            </Link>
          ))}
          <button
            onClick={handleLogout}
            className="list-group-item list-group-item-action border-0 px-4 py-2"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;