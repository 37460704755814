import JsConvertCase from 'js-convert-case'

export const snakeKeys = (obj: Object | Array<Object>): any => {
  if (Array.isArray(obj)) {
    return Object.keys(obj).map(key => snakeCaseConvert(obj[key as any]))
  }

  return snakeCaseConvert(obj)
}

export const camelKeys = (obj: Object | Array<Object>): any => {
  if (Array.isArray(obj)) {
    return Object.keys(obj).map(key => camelCaseConvert(obj[key as any]))
  }

  return camelCaseConvert(obj)
}

const snakeCaseConvert = (obj: Object) => JsConvertCase.snakeKeys(obj, { recursive: true, recursiveInArray: true })
const camelCaseConvert = (obj: Object) => JsConvertCase.camelKeys(obj, { recursive: true, recursiveInArray: true })
