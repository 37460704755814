$(document).ready(function () {
  if (!document.querySelector('#walk_through_step_response_social_security_number') && 
      !document.querySelector('#walk_through_step_response_social_security_number_last_four')) return;

  const ssnInput = $('#walk_through_step_response_social_security_number');
  const ssnLastFourInput = $('#walk_through_step_response_social_security_number_last_four');
  let digits = '';
  let oldDigits = '';
  let isBackspace = false;

  if(ssnInput) {
    ssnInput.on('keydown', function (e) {
      isBackspace = e.key === 'Backspace';
    });
  
    ssnInput.on('input', function () {
      let val = this.value;
      let newDigits = val.replace(/\D/g, '');
  
      if (newDigits.length < oldDigits.length) {
        // deleted a digit
        digits = newDigits;
      } else if (newDigits.length > oldDigits.length) {
        // added a digit
        digits = newDigits;
      } else if (isBackspace) {
        // deleted a non-digit character (like a dash)
        digits = digits.slice(0, -1);
      }
  
      // Format the digits with dashes
      let formattedVal = formatSSN(digits);
  
      this.value = formattedVal;
      oldDigits = digits;
      isBackspace = false;
    });
  }

  if(ssnLastFourInput) {
    ssnLastFourInput.on('input', function() {
      this.value = this.value.replace(/\D/g, '').slice(0, 4);
    });
  }

  function formatSSN(digits) {
    if (digits.length >= 5) {
      return digits.slice(0, 3) + '-' + digits.slice(3, 5) + '-' + digits.slice(5, 9);
    } else if (digits.length >= 3) {
      return digits.slice(0, 3) + '-' + digits.slice(3);
    } else {
      return digits;
    }
  }
});
