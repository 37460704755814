import { Controller } from "@hotwired/stimulus"

const POSTHOG_EVENT_NAME = "pilot:completions:anchor_clicked"
const SANATIZE_PARAM_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "_gl"
]

export default class extends Controller {
  // *** Getters

  get props() {
    return JSON.parse(this.data.get("props") || "{}")
  }

  // *** Lifecycle

  connect() {
    this.element.addEventListener("click", this.handleClick.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick.bind(this))
  }

  // *** Events

  handleClick() {
    const url = this.sanitizeUrl(this.element.href)

    window.posthog.capture(POSTHOG_EVENT_NAME, { ...this.props, url })
  }

  // *** Helpers

  sanitizeUrl(url) {
    const urlObj = new URL(url)
    const params = new URLSearchParams(urlObj.search)

    SANATIZE_PARAM_KEYS.forEach(param => {
      params.delete(param)
    })

    urlObj.search = params.toString()

    return urlObj.toString()
  }
}
