import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import { filter } from 'lodash'

import { RootState } from "../../store"
import { Completion, CompletionState } from "../../types"

export const completionsAdapter = createEntityAdapter({
  selectId: (completion: Completion) => completion.id,
  sortComparer: (a: Completion, b: Completion) => a.createdAt - b.createdAt,
})

const completionsSelector = completionsAdapter.getSelectors<RootState>(
  (state) => state.completions
)

export default completionsSelector

export const getAllCompletions = (state: RootState) => completionsSelector.selectAll(state)
export const getAllCompletionIds = (state: RootState) => completionsSelector.selectIds(state)
export const getCompletionById = (state: RootState, completionId: number) => completionsSelector.selectById(state, completionId)
export const getChannelState = (state: RootState) => state.completions.channelState
export const isCreating = (state: RootState) => state.completions.creating
export const isFetching = (state: RootState) => state.completions.fetching
export const getHasNextPage = (state: RootState) => state.completions.hasNextPage

export const getProcessingCompletions = createSelector(
  [getAllCompletions],
  (completions) =>
    filter(
      completions,
      (completion: Completion) => CompletionState.Processing === completion.state
    )
)

export const isProcessing = createSelector(
  [getProcessingCompletions],
  (completions) => 0 !== completions.length
)

export const getOldestCompletion = createSelector(
  [getAllCompletions],
  (completions) => completions.length > 0 ? completions[0] : undefined
)
