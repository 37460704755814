import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './consumer_dashboard/Dashboard';

const ConsumerDashboardRoot = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/my_dashboard"
          element={<Dashboard />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default ConsumerDashboardRoot; 