import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { completionsAdapter } from "./selectors"
import { fetchCompletions, createCompletion } from './actions'
import { ChannelState, Completion, CompletionDelta } from '../../types'

export const completionsSlice = createSlice({
  name: 'completions',
  initialState: completionsAdapter.getInitialState({
    channelState: ChannelState.Disconnected,
    creating: false,
    fetching: false,
    hasNextPage: false,
  }),
  reducers: {
    upsertCompletion: completionsAdapter.upsertOne,
    updateChannelState(state, action: PayloadAction<ChannelState>) {
      state.channelState = action.payload
    },
    appendAssistantCompletionDelta(state, action: PayloadAction<CompletionDelta>) {
      let assistantResponse = state.entities[action.payload.id].assistantResponse

      if (assistantResponse) {
        assistantResponse += action.payload.assistantResponseDelta
      } else {
        assistantResponse = action.payload.assistantResponseDelta
      }

      state.entities[action.payload.id] = {
        ...state.entities[action.payload.id],
        assistantResponse
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompletions.pending, (state) => {
      state.fetching = true
    });
    builder.addCase(fetchCompletions.fulfilled, (state, action) => {
      const payload = { ...action.payload } as { data: Completion[], hasNextPage: boolean };

      state.fetching = false
      state.hasNextPage = payload.hasNextPage
      completionsAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(fetchCompletions.rejected, (state) => {
      state.fetching = false
    });

    builder.addCase(createCompletion.pending, (state) => {
      state.creating = true
    });
    builder.addCase(createCompletion.fulfilled, (state, action) => {
      const payload = { ...action.payload } as { data: Completion };

      state.creating = false
      completionsAdapter.upsertOne(state, payload.data);
    });
    builder.addCase(createCompletion.rejected, (state) => {
      state.creating = false
    });
  }
})

export default completionsSlice.reducer

export const { appendAssistantCompletionDelta, updateChannelState, upsertCompletion } = completionsSlice.actions
