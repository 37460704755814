import React from 'react'
import Container from 'react-bootstrap/Container'
import { Provider } from 'react-redux'

import PilotApp from './PilotApp'
import { AppType } from './types'
import store from './store'

function Page({ props: { authenticated } }: any) {
  return (
    <Provider store={store}>
      <Container>
        <PilotApp appType={AppType.Card} authenticated={authenticated} />
      </Container>
    </Provider>
  )
}

export default Page
