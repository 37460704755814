import React from 'react'
import ReactMarkdown from 'react-markdown'

import AnchorRenderer from './markdownRenderers/AnchorRenderer'

type Props = {
  completionId: number;
  content: string;
}

function Markdown({ completionId, content }: Props) {
  if ("" === content) {
    return (
      <p />
    )
  } else {
    const components = {
      a: ({ children, href }) => (
        <AnchorRenderer
          href={href}
          completionId={completionId}
          children={children}
        />
      )
    }

    return (
      <ReactMarkdown components={components}>
        {content}
      </ReactMarkdown>
    )
  }
}

export default Markdown
