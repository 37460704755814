import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardTitle, CardDescription, CardFooter } from '../ui/Card';

const HIDE_DURATION_DAYS = 7;

const CallToActionCards = () => {
  const [hiddenCards, setHiddenCards] = useState({
    soloSettle: false,
    debtAnswer: false
  });

  useEffect(() => {
    Object.keys(hiddenCards).forEach(cardKey => {
      const hiddenUntil = localStorage.getItem(`hidden_${cardKey}`);
      if (hiddenUntil && new Date(hiddenUntil) > new Date()) {
        setHiddenCards(prev => ({ ...prev, [cardKey]: true }));
      } else if (hiddenUntil) {
        localStorage.removeItem(`hidden_${cardKey}`);
      }
    });
  }, []);

  const hideCard = (cardKey) => {
    const hiddenUntil = new Date();
    hiddenUntil.setDate(hiddenUntil.getDate() + HIDE_DURATION_DAYS);
    localStorage.setItem(`hidden_${cardKey}`, hiddenUntil.toISOString());
    setHiddenCards(prev => ({ ...prev, [cardKey]: true }));
  };

  if (hiddenCards.soloSettle && hiddenCards.debtAnswer) {
    return null;
  }

  return (
    <div className="row mb-8">
      {!hiddenCards.soloSettle && (
        <div className="col-12 mb-4">
          <Card>
            <CardContent>
              <div className="d-flex justify-content-between align-items-start">
                <CardTitle><h2>Try SoloSettle</h2></CardTitle>
                <button 
                  onClick={() => hideCard('soloSettle')} 
                  className="btn-close"
                  style={{ transform: 'scale(1.5)' }}
                  aria-label="Hide SoloSettle Card"
                  title="Hide SoloSettle Card"
                />
              </div>
              <CardDescription>
                Are you ready to resolve your debt once and for all? SoloSettle makes it easy to settle with your collector.
              </CardDescription>
              <CardFooter>
                <a href="/solosettle" className="btn btn-primary fs-6">Learn More</a>
              </CardFooter>
            </CardContent>
          </Card>
        </div>
      )}
      {!hiddenCards.debtAnswer && (
        <div className="col-12">
          <Card>
            <CardContent>
              <div className="d-flex justify-content-between align-items-start">
                <CardTitle><h2>File an Answer on your Lawsuit</h2></CardTitle>
                <button 
                  onClick={() => hideCard('debtAnswer')} 
                  className="btn-close"
                  style={{ transform: 'scale(1.5)' }}
                  aria-label="Hide Debt Answer Card"
                  title="Hide Debt Answer Card"
                />
              </div>
              <CardDescription>
                You made an offer to settle but you haven't filed an Answer yet on your lawsuit. Filing an Answer is crucial for settling.
              </CardDescription>
              <CardFooter>
                <a href="/debt_answer" className="btn btn-primary fs-6">File an Answer</a>
              </CardFooter>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default CallToActionCards; 