import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchRequest } from "@rails/request.js";

import { camelKeys, snakeKeys } from "../../utils/json";
import { toastError } from "../../utils/toast";

const PILOT_COMPLETIONS_PATH = "/pilot-completions.json"

type FetchCompletionsParams = {
  createdAt?: number;
};

export const fetchCompletions = createAsyncThunk(
  "completions/fetchCompletions",
  async ({ createdAt }: FetchCompletionsParams) => {
    const request = new FetchRequest("GET", PILOT_COMPLETIONS_PATH, {
      responseKind: "json",
      query: createdAt ? snakeKeys({ createdAt }) : null,
    })

    const response = await request.perform()

    if (response.ok) {
      const json = await response.json

      return camelKeys(json)
    } else {
      const message = "Unable to load messages!\nPlease try again in a few minutes.";

      toastError(message)
      console.error(message)
      throw new Error(message)
    }
  }
)

type CreateCompletionParams = {
  userRequest: string;
};

export const createCompletion = createAsyncThunk(
  "completions/createCompletion",
  async ({ userRequest }: CreateCompletionParams) => {
    const request = new FetchRequest("POST", PILOT_COMPLETIONS_PATH, {
      responseKind: "json",
      body: snakeKeys({ pilotCompletion: { userRequest } }),
    })

    const response = await request.perform();

    if (response.ok) {
      const json = await response.json

      return camelKeys(json)
    } else {
      const message = "Unable to create question!\nPlease try again in a few minutes.";

      toastError(message)
      console.error(message)
      throw new Error(message)
    }
  }
)
