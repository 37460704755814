import React, { useState, useEffect } from 'react';
import Layout from './Layout';

const PaymentDetails = () => {
  const [formData, setFormData] = useState({
    payee: '',
    mailing_name: '',
    mailing_street: '',
    mailing_city: '',
    mailing_state: '',
    mailing_zip: ''
  });

  useEffect(() => {
    fetchLawFirmData();
  }, []);

  const fetchLawFirmData = async () => {
    try {
      const response = await fetch('/collectors/api/get_law_firm');
      const data = await response.json();
      if (data.law_firm) {
        setFormData({
          payee: data.law_firm.payee || '',
          mailing_name: data.law_firm.mailing_name || null,
          mailing_street: data.law_firm.mailing_street || '',
          mailing_city: data.law_firm.mailing_city || '',
          mailing_state: data.law_firm.mailing_state || '',
          mailing_zip: data.law_firm.mailing_zip || ''
        });
      }
    } catch (error) {
      alert('Error loading law firm data');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/collectors/api/update_firm_payment_details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ law_firm: formData })
      });

      const data = await response.json();
      if (response.ok) {
        alert('Payment details updated successfully');
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert('Error updating payment details');
    }
  };

  return (
    <Layout>
      <h1 className="display-3 mb-4">Payment Details</h1>
      <div className="mb-4">
        <h2>Enter your firms payment details here.</h2>
        <div className="row">
          <div className="col-xl-12 col-lg-7 col-md-10 col-sm-9 col-10">
            <p className="lead text-muted">
              If you're being paid by check, each check will be sent in accord with these payment details. Note that you may not enter a PO Box.
              <br /><br/>
              For faster payment, set up direct deposit.
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-md-6">
              {Object.entries({
                payee: 'Payee Name',
                mailing_name: 'Mailing Name (optional)',
                mailing_street: 'Street Address',
                mailing_city: 'City'
              }).map(([field, label]) => (
                <div className="mb-3" key={field}>
                  <label htmlFor={field} className="form-label">{label}</label>
                  <input
                    type="text"
                    className="form-control"
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                  />
                </div>
              ))}

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="mailing_state" className="form-label">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mailing_state"
                    name="mailing_state"
                    value={formData.mailing_state}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="mailing_zip" className="form-label">ZIP Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mailing_zip"
                    name="mailing_zip"
                    value={formData.mailing_zip}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Update Payment Details
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default PaymentDetails;