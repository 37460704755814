import React from 'react'
import classNames from 'classnames';

import { ChannelState } from '../types'

type Props = {
  channelState: ChannelState;
}

function ChannelStateDot({ channelState }: Props) {
  const dotClassNames = classNames({
    "pilot-channel-state-dot": true,
    "connected": ChannelState.Connected === channelState,
    "disconnected": ChannelState.Disconnected === channelState,
  })

  return (
    <div className={dotClassNames}><span>&nbsp;</span></div>
  )
}

export default ChannelStateDot
