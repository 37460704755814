import React from 'react';

const Card = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`card rounded-top-start-3 rounded-bottom-end-3 shadow-lg border-0 ${className}`}
    {...props}
  >
    {children}
  </div>
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`card-header rounded-top-start-3 border-bottom-0 d-flex flex-column gap-2 ${className}`}
    {...props}
  >
    {children}
  </div>
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`card-title font-sans-serif ${className}`}
    {...props}
  >
    {children}
  </div>
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`card-text text-body-secondary fs-6 mb-0 ${className}`}
    {...props}
  >
    {children}
  </div>
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`card-body d-flex flex-column ${className}`}
    {...props}
  >
    {children}
  </div>
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className = '', children, ...props }, ref) => (
  <div
    ref={ref}
    className={`card-footer border-top-0 d-flex align-items-center mt-3 p-0 ${className}`}
    {...props}
  >
    {children}
  </div>
));
CardFooter.displayName = "CardFooter";

export { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter }; 