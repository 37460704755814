import React, { useState, useEffect } from 'react';
import AccountRow from './AccountRow';
import { extractCentsFromDollarString } from '../../../other/money';

const SortIcon = ({ column, currentSort }) => {
  const isActive = currentSort.column === column;
  const direction = currentSort.direction;

  return (
    <svg width="20" height="20" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="ms-1 me-2">
      <path d="M9 10.5L6 13.5L3 10.5" stroke={isActive && direction === 'desc' ? "#666666" : "#9E9E9E"} strokeWidth={isActive && direction === 'desc' ? "2" : "1"}/>
      <path d="M3 7.5L6 4.5L9 7.5" stroke={isActive && direction === 'asc' ? "#666666" : "#9E9E9E"} strokeWidth={isActive && direction === 'asc' ? "2" : "1"}/>
    </svg>
  );
};

const AccountsTable = ({ 
  accounts, 
  setAccounts,
  selectedFilterValues, 
  onLoadMore, 
  hasMorePages, 
  hasPaymentDetails, 
  validFirstPaymentDate 
}) => {
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [sortConfig, setSortConfig] = React.useState({
    column: null,
    direction: 'asc'
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdowns = document.querySelectorAll('.action-dropdown');
      const buttons = document.querySelectorAll('.action-button');
      
      if (
        !Array.from(dropdowns).some(dropdown => dropdown.contains(event.target)) &&
        !Array.from(buttons).some(button => button.contains(event.target))
      ) {
        setActiveDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSort = (column) => {
    const direction = sortConfig.column === column && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ column, direction });

    const actionOrder = [
      "Consumer Offer",
      "Lawyer Sent Confusing Response to Offer",
      "Consumer Counteroffer",
      "Lawyer Counteroffer",
      "Lawyer Accept",
      "Consumer Accept",
      "Lawyer Sent Confusing Response To Payment Details Request",
      "Lawyer Sent Payment Details",
      "Lawyer Sent Settlement Agreement",
      "Consumer Signed Settlement Agreement"
    ];

    const sortedAccounts = [...accounts].sort((a, b) => {
      const compareValue = (val1, val2) => {
        if (val1 < val2) return -1;
        if (val1 > val2) return 1;
        return 0;
      };

      let comparison = 0;
      switch (column) {
        case 'name':
          comparison = compareValue(a.customer_name?.toLowerCase(), b.customer_name?.toLowerCase());
          break;
        case 'birthdate':
          comparison = compareValue(new Date(a.birthdate), new Date(b.birthdate));
          break;
        case 'reference':
          comparison = compareValue(a.civil_number?.toLowerCase(), b.civil_number?.toLowerCase());
          break;
        case 'lawsuitState':
          comparison = compareValue(a.lawsuit_state?.toLowerCase(), b.lawsuit_state?.toLowerCase());
          break;
        case 'plaintiff':
          comparison = compareValue(a.plaintiff_name?.toLowerCase(), b.plaintiff_name?.toLowerCase());
          break;
        case 'amount':
          comparison = compareValue(parseFloat(a.lawsuit_amount?.replace(/[$,]/g, '')) || 0, parseFloat(b.lawsuit_amount?.replace(/[$,]/g, '')) || 0);
          break;
        case 'status':
          const indexA = actionOrder.indexOf(a.last_action);
          const indexB = actionOrder.indexOf(b.last_action);
          comparison = compareValue(indexA, indexB);
          break;
        case 'statusDate':
          comparison = compareValue(new Date(a.latest_event.created_at), new Date(b.latest_event.created_at));
          break;
        case 'offerAmount':
          comparison = compareValue(parseFloat(a.latest_historical_offer?.total_amount_cents) || 0, parseFloat(b.latest_historical_offer?.total_amount_cents) || 0);
          break;
        case 'numberOfPayments':
          comparison = compareValue(parseInt(a.latest_historical_offer?.payments) || 0, parseInt(b.latest_historical_offer?.payments) || 0);
          break;
        case 'percentage':
          comparison = compareValue(
            (a.latest_historical_offer?.total_amount_cents && a.lawsuit_amount ? 
              Math.round((a.latest_historical_offer.total_amount_cents / extractCentsFromDollarString(a.lawsuit_amount)) * 100) : 0),
            (b.latest_historical_offer?.total_amount_cents && b.lawsuit_amount ?
              Math.round((b.latest_historical_offer.total_amount_cents / extractCentsFromDollarString(b.lawsuit_amount)) * 100) : 0)
          );
          break;
        case 'firstPayment':
          comparison = compareValue(new Date(a.latest_historical_offer?.first_payment_date), new Date(b.latest_historical_offer?.first_payment_date));
          break;
        default:
          comparison = 0;
      }

      return direction === 'asc' ? comparison : -comparison;
    });

    setAccounts(sortedAccounts);
  };

  const columnsToShow = selectedFilterValues.columns || [];
  
  return (
    <>
      <div id="table-container" className="table-responsive" style={{ position: 'relative', minHeight: '500px' }}>
        <table className="table table-sm" style={{ verticalAlign: 'middle' }}>
          <thead>
            <tr>
              {columnsToShow.includes('Name') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex">
                    Name <SortIcon column="name" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Birthdate') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('birthdate')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center">
                    Birthdate <SortIcon column="birthdate" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Reference') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('reference')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center">
                    Reference <SortIcon column="reference" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Lawsuit State') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('lawsuitState')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center">
                    Lawsuit State <SortIcon column="lawsuitState" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Plaintiff') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('plaintiff')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center">
                    Plaintiff <SortIcon column="plaintiff" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Amount') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('amount')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '100px' }}>
                    Amount <SortIcon column="amount" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Status') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '110px' }}>
                    Status <SortIcon column="status" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Status Date') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('statusDate')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '100px' }}>
                    Status Date <SortIcon column="statusDate" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('Offer Amount') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('offerAmount')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '100px' }}>
                    Offer Amount <SortIcon column="offerAmount" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('# Pays') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('numberOfPayments')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '80px' }}>
                    # Pays <SortIcon column="numberOfPayments" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('%') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('percentage')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '80px' }}>
                    % <SortIcon column="percentage" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              {columnsToShow.includes('First Payment') && (
                <th scope="col" className="align-middle px-2" onClick={() => handleSort('firstPayment')} style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '100px' }}>
                    First Payment <SortIcon column="firstPayment" currentSort={sortConfig} />
                  </div>
                </th>
              )}
              <th scope="col"></th>
              <th scope="col" className="align-middle px-2" onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                <div className="d-flex align-items-center justify-content-center" style={{ minWidth: '80px' }}>
                  Action <SortIcon column="status" currentSort={sortConfig} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="small">
            {accounts.map(account => (
              <AccountRow
                key={account.id}
                selectedFilterValues={selectedFilterValues}
                validFirstPaymentDate={validFirstPaymentDate}
                hasPaymentDetails={hasPaymentDetails}
                account={account}
                columnsToShow={columnsToShow}
                activeDropdownId={activeDropdownId}
                setActiveDropdownId={setActiveDropdownId}
              />
            ))}
          </tbody>
        </table>

        {hasMorePages && (
          <div className="text-center mt-4">
            <button onClick={onLoadMore} className="btn btn-primary">
              Load More
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountsTable;
