import React from 'react';
import { formatDollars } from '../../../other/money';

export const ArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5V19" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 12H7" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 18L21 12L15 6" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);


const AccountDetails = ({ account: info }) => {
    const downloadAuthorizationLetter = async (event, documentId) => {
      event.preventDefault();
      const linkElement = event.target;
      const statusElement = document.getElementById(`download-status-${documentId}`);
      const errorElement = document.getElementById(`download-error-${documentId}`);
      
      errorElement.querySelector('span').textContent = '';
      errorElement.classList.add('d-none');
      
      linkElement.classList.add('d-none');
      statusElement.classList.remove('d-none');
      
      try {
        const response = await fetch('/collectors/api/render_document', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          },
          body: JSON.stringify({ customer_document_id: documentId })
        });
        
        if (!response.ok) {
          throw new Error('Failed to download file');
        }

        // Get the blob from the response
        const blob = await response.blob();
        
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        
        // Create a sanitized customer name for the filename
        const sanitizedName = info.civil_number
          ? info.civil_number.replace(/[^a-z0-9]/gi, '_').toLowerCase()
          : 'authorization_letter';
        
        // Create and trigger download
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `authorization_letter__${sanitizedName}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        
        // Cleanup
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        
      } catch (error) {
        console.error('Error:', error);
        errorElement.querySelector('span').textContent = error.message;
        errorElement.classList.remove('d-none');
      } finally {
        statusElement.classList.add('d-none');
        linkElement.classList.remove('d-none');
      }
    };

    const downloadHistoryCSV = (documentId, referenceNumber, events) => {
        // Helper function to sanitize strings for CSV
        const sanitizeForCSV = (str) => {
            if (!str) return '';
            // Replace double quotes with two double quotes (CSV standard)
            // Remove any newlines/carriage returns
            return `"${str.toString().replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`;
        };

        // Add account information header
        const csvRows = [
            ['Account Information', '', '', '', ''].join(','),
            ['Plaintiff Name', sanitizeForCSV(info.plaintiff_name || 'n/a'), '', '', ''].join(','),
            ['Lawsuit State', sanitizeForCSV(info.lawsuit_state || 'n/a'), '', '', ''].join(','),
            ['Consumer Name', sanitizeForCSV(info.customer_name || 'n/a'), '', '', ''].join(','),
            ['Reference Number', sanitizeForCSV(info.civil_number || 'n/a'), '', '', ''].join(','),
            ['', '', '', '', ''].join(','), // Empty row for spacing
            ['Activity Log', '', '', '', ''].join(','),
            ['Party', 'Reference', 'Action', 'Date', 'Time'].join(',')
        ];
        
        // Add event data
        events.forEach(event => {
            const date = new Date(event.created_at);
            let actionText = info.event_id_to_english_text[event.id];
            
            // Add disclosures to action text if they exist
            if (event.disclosures?.length > 0) {
                actionText += ' The collector made the following disclosures: ';
                actionText += event.disclosures.map(disclosure => disclosure.text).join(' ');
            }

            const row = [
                sanitizeForCSV(event.party.charAt(0).toUpperCase() + event.party.slice(1)),
                sanitizeForCSV(referenceNumber),
                sanitizeForCSV(actionText),
                sanitizeForCSV(date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })),
                sanitizeForCSV(date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }))
            ];
            csvRows.push(row.join(','));
        });

        // Create and trigger download
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `activity_log_${documentId}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
      <div className="card-body m-3">
        <h2 className="mb-2">Account Information</h2>
        <p className="mb-1"><strong>Plaintiff Name:</strong> {info.plaintiff_name || 'n/a'}</p>
        <p className="mb-1"><strong>Lawsuit State:</strong> {info.lawsuit_state || 'n/a'}</p>
        <p className="mb-1"><strong>Consumer Name:</strong> {info.customer_name || 'n/a'}</p>
        <p className="mb-1"><strong>Reference Number:</strong> {info.civil_number || 'n/a'}</p>
        <p className="mb-1"><strong>Social Security Number:</strong> {info?.social_security_number?.social_security_number || (info?.social_security_number?.social_security_number_last_four ? `XXX-XX-${info.social_security_number.social_security_number_last_four}` : 'n/a')}</p>
        <p className="mb-1"><strong>Date of Birth:</strong> {info.birthdate}</p>
        <p className="mb-1">
          <strong>Address:</strong>{' '}
          {info.customer_mailing_address ? (
            `${info.customer_mailing_address.customer_street}, ${info.customer_mailing_address.customer_city}, ${info.customer_mailing_address.customer_state} ${info.customer_mailing_address.customer_zip_code}`
          ) : (
            'n/a'
          )}
        </p>
        <p className="mb-1"><strong>SoloSettle Email:</strong> {info.email || 'n/a'}</p>
        <p className="mb-1">{info.lawsuit_exists ? 'Litigation Account' : 'Pre-litigation Account'}</p>
  
        {info.settlement_negotiation_events?.length > 0 && (
          <p className="mb-1">
            <a
              href="#"
              className="text-primary"
              id={`download-link-${info.customer_document.id}`}
              onClick={(e) => downloadAuthorizationLetter(e, info.customer_document.id)}
            >
              Download authorization letter
            </a>
            <div id={`download-status-${info.customer_document.id}`} className="d-none">
              <span className="text-dark">
                Downloading... <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </span>
            </div>
            <div id={`download-error-${info.customer_document.id}`} className="d-none">
              <span className="text-danger"></span>
            </div>
          </p>
        )}
  
        <h3 className="mt-4 mb-2">Hardship Information</h3>
        {Object.entries(info.hardship || {})
          .filter(([_, value]) => value !== "0")
          .map(([key, value]) => (
            <p key={key} className="mb-1 ms-5">{value}</p>
          ))}
        
        {info.monthly_income_total && (
          <p className="mb-1 ms-5">
            Monthly Income: {formatDollars(info.monthly_income_total)}
          </p>
        )}
        
        {info.monthly_expenses_total && (
          <p className="mb-1 ms-5">
            Monthly Expenses: {formatDollars(info.monthly_expenses_total)}
          </p>
        )}
  
        <h3 className="mt-4 mb-2">History</h3>
        <div className="ms-5">
            <div className="d-flex justify-content-end mb-2">
                <a
                    href="#"
                    className="text-decoration-none"
                    onClick={(e) => {
                        e.preventDefault();
                        downloadHistoryCSV(info.customer_document.id, info.civil_number, info.settlement_negotiation_events || []);
                    }}
                >
                    <ArrowIcon />
                </a>
            </div>
            <table className="table table-sm table-borderless">
                <thead>
                    <tr>
                        <th>PARTY</th>
                        <th>ACTION</th>
                        <th>DATE</th>
                        <th>TIME</th>
                    </tr>
                </thead>
                <tbody>
                    {(info.settlement_negotiation_events || []).map((event, index) => {
                        const disclosures = event.disclosures?.map(d => d.text) || [];
                        return (
                            <tr key={index}>
                                <td>{event.party.charAt(0).toUpperCase() + event.party.slice(1)}</td>
                                <td>
                                    {info.event_id_to_english_text[event.id]}
                                    
                                    {disclosures.length > 0 && (
                                        <div>The collector made the following disclosures:</div>
                                    )}
                                    {disclosures.map(disclosureText => (
                                        <div key={disclosureText}>{disclosureText}</div>
                                    ))}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>{new Date(event.created_at).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{new Date(event.created_at).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
      </div>
    );
  };

export default AccountDetails;
