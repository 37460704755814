import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Loading() {
  return (
    <div className="mb-4 d-flex justify-content-center align-items-center">
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      <span className="ms-2">loading...</span>
    </div>
  )
}

export default Loading
