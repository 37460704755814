import React from 'react'
import { isEqual } from 'lodash'
import classNames from 'classnames'

import ListItem from './ListItem'
import { getAllCompletionIds, getHasNextPage, getOldestCompletion, isFetching } from './selectors'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { fetchCompletions } from './actions'
import Loading from '../../components/Loading'
import Bubble from './Bubble'
import { CompletionRole, CompletionState } from '../../types'

const SCROLL_TOP_BUFFER = 100

function List() {
  const dispatch = useAppDispatch()
  const completionIds = useAppSelector(getAllCompletionIds, isEqual)
  const fetching = useAppSelector(isFetching, isEqual)
  const hasNextPage = useAppSelector(getHasNextPage, isEqual)
  const oldestCompletion = useAppSelector(getOldestCompletion, isEqual)
  const buttonScrollHeightRef = React.useRef<number>(0)
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const innerScrollRef = React.useRef<HTMLDivElement>(null)

  const calculateBottomScrollHeight = () => {
    buttonScrollHeightRef.current = scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop - scrollRef.current!.clientHeight
  }

  const fetchNextPage = () => {
    if (hasNextPage && oldestCompletion && !fetching) {
      dispatch(fetchCompletions({ createdAt: oldestCompletion.createdAt }))
    }
  }

  const handleScroll = () => {
    if (SCROLL_TOP_BUFFER > scrollRef.current!.scrollTop && !fetching) {
      fetchNextPage()
      calculateBottomScrollHeight()
    }
  }

  const handleResize = () => {
    scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight - scrollRef.current!.clientHeight - buttonScrollHeightRef.current
  }

  React.useEffect(() => {
    if (!innerScrollRef.current) return
    const resizeObserver = new ResizeObserver(() => handleResize())
    resizeObserver.observe(innerScrollRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  React.useEffect(() => {
    dispatch(fetchCompletions({}))
  }, [])

  const containerClassNames = classNames({
    "pilot-completions-list": true,
    // "full-height": 0 === completionIds.length
  })

  return (
    <div className={containerClassNames} ref={scrollRef} onScroll={handleScroll}>
      <div className="pilot-completions-list__inner_scroll" ref={innerScrollRef}>
        {(0 === completionIds.length && !fetching) ? <Bubble content="Tell me about your situation." role={CompletionRole.Assistant} state={CompletionState.Successful} /> : null}
        {fetching ? <Loading /> : null}
        {completionIds.map((completionId) => <ListItem key={completionId} completionId={completionId} />)}
      </div>
    </div>
  )
}

export default List
