import React from 'react'
import classNames from 'classnames';

import { CompletionRole, CompletionState } from '../../types';
import Markdown from '../../components/Markdown';

type Props = {
  completionId?: number;
  content: string;
  role: CompletionRole;
  state: CompletionState;
}

function Bubble({ completionId, content, role, state }: Props) {
  const containerClassNames = classNames({
    "pilot-completions-bubble": true,
    "assistant": CompletionRole.Assistant === role,
    "user": CompletionRole.User === role,
    "processing": CompletionState.Processing === state,
    "successful": CompletionState.Successful === state,
    "failed": CompletionState.Failed === state,
  })

  return (
    <div className={containerClassNames}>
      <div className="pilot-completions-bubble__content">
        {CompletionRole.Assistant === role ? <Markdown content={content} completionId={completionId} /> : content}
      </div>
    </div>
  )
}

export default Bubble
