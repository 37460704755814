import React, { useState, useEffect } from 'react';
import CurrencyInput from '../../../shared/CurrencyInput';
import ActionSelector from './ActionSelector';

const DEFAULT_ACCEPTANCE_DISCLOSURE = 'This acceptance is a communication from a debt collector';
const DEFAULT_COUNTEROFFER_DISCLOSURE = 'This counteroffer is a communication from a debt collector';

const AcceptOrCounteroffer = ({ account, validFirstPaymentDate, onSuccess }) => {
  const [selectedAction, setSelectedAction] = useState(
    account.latest_historical_offer?.first_payment_date >= validFirstPaymentDate 
      ? 'accept' 
      : 'counteroffer'
  );
  
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    total_amount: '',
    payments: '',
    first_payment_date: '',
    disclosures: [selectedAction === 'accept' ? 
      DEFAULT_ACCEPTANCE_DISCLOSURE :
      DEFAULT_COUNTEROFFER_DISCLOSURE, '']
  });

  useEffect(() => {
    const disclosuresCpy = [...formData.disclosures];
    disclosuresCpy[0] = selectedAction === 'accept' ? 
      DEFAULT_ACCEPTANCE_DISCLOSURE :
      DEFAULT_COUNTEROFFER_DISCLOSURE;
    setFormData({ ...formData, disclosures: disclosuresCpy });
  }, [selectedAction]);

  // Add a unique row identifier
  const rowId = `action-row-${account.customer_document.id}`;

  // Modify useEffect to run once on mount
  useEffect(() => {
    // Adjust textareas
    setTimeout(() => {
      document.querySelectorAll('textarea').forEach(adjustTextareaHeight);
    }, 0);
  }, []); // Empty dependency array - runs once on mount

  // Add useEffect for click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdowns = document.querySelectorAll(`#${rowId} .action-dropdown`);
      const buttons = document.querySelectorAll(`#${rowId} .action-button`);
      
      // Don't close if clicking on any button in this row
      if (Array.from(buttons).some(button => button.contains(event.target))) {
        return;
      }
      
      // Close if clicking outside dropdowns
      if (!Array.from(dropdowns).some(dropdown => dropdown.contains(event.target))) {
        setTimeout(() => {
          setActiveDropdown(null);
        }, 0);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [rowId]);

  const handleAcceptOffer = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    
    if (!confirm("Are you sure you want to accept this offer?")) {
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    const response = await fetch('/collectors/api/accept_offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ 
        customer_document_id: account.customer_document.id,
        disclosures: formData.disclosures.filter(d => d.trim() !== '')
      })
    });
    const data = await response.json();
    if (data.success) {
      onSuccess?.(data);
      return data;
    } else {
      alert(data.error || 'Error processing request');
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  const handleCounterOffer = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    if (!confirm("Are you sure you want to submit this counteroffer?")) {
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    const response = await fetch('/collectors/api/create_counteroffer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        customer_document_id: account.customer_document.id,
        total_amount: formData.total_amount,
        payments: formData.payments,
        first_payment_date: formData.first_payment_date,
        disclosures: formData.disclosures.filter(d => d.trim() !== '')
      })
    });

    const data = await response.json();
    if (data.success) {
      onSuccess?.(data);
      return data;
    } else {
      alert(data.error || 'Error processing request');
      setIsLoading(false);
      setIsDisabled(false);
    }
  };
  
  // Add form change handler
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Add this new function to handle dropdown visibility
  const ensureDropdownVisible = () => {
    const tableContainer = document.getElementById('table-container');
    const dropdown = document.querySelector(`#${rowId} .action-dropdown`);
    
    if (tableContainer && dropdown) {
      const dropdownRect = dropdown.getBoundingClientRect();
      const containerRect = tableContainer.getBoundingClientRect();
      
      // Calculate if dropdown extends beyond visible area
      const overflowAmount = (dropdownRect.bottom - containerRect.bottom) + 20; // 20px buffer
      
      if (overflowAmount > 0) {
        const scrollOptions = {
          top: tableContainer.scrollTop + overflowAmount,
          behavior: 'smooth'
        };
        tableContainer.scroll(scrollOptions);
      }
    }
  };

  // Update handleDisclosureChange to include scrolling
  const handleDisclosureChange = (index, value) => {
    if (index === 0) return;
    
    setFormData(prev => {
      const newDisclosures = [...prev.disclosures];
      newDisclosures[index] = value;
      
      if (index === newDisclosures.length - 1 && value.trim() !== '') {
        newDisclosures.push('');
      }
      
      return {
        ...prev,
        disclosures: newDisclosures
      };
    });

    // Add setTimeout to ensure the DOM has updated
    setTimeout(ensureDropdownVisible, 0);
  };

  // Update adjustTextareaHeight to include scrolling
  const adjustTextareaHeight = (textarea) => {
    if (!textarea) return;
    const originalHeight = textarea.style.height;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    
    // Only trigger scroll if height actually changed
    if (originalHeight !== textarea.style.height) {
      setTimeout(ensureDropdownVisible, 0);
    }
  };

  // Update click handler for buttons
  const handleButtonClick = (action) => {
    // If clicking the same button that's already active, close it
    if (activeDropdown === action) {
      setActiveDropdown(null);
    } else {
      // If clicking a different button, switch to it immediately
      setActiveDropdown(action);
      
      // Scroll to make dropdown visible
      setTimeout(() => {
        document.querySelectorAll('textarea').forEach(adjustTextareaHeight);
        ensureDropdownVisible();
      }, 0);
    }
    setSelectedAction(action);
  };

  const handleDeleteDisclosure = (index) => {
    if (index === 0) return; // Don't allow deletion of the first disclosure
    
    setFormData(prev => ({
      ...prev,
      disclosures: prev.disclosures.filter((_, i) => i !== index)
    }));

    // Ensure dropdown remains visible after deletion
    setTimeout(ensureDropdownVisible, 0);
  };

  const showAcceptButton = account.latest_historical_offer?.first_payment_date >= validFirstPaymentDate;

  return (
    <div className="position-relative" id={rowId}>
      <div className="d-flex align-items-start">
        <span className="position-relative">
          {showAcceptButton && (
            <button
              type="button"
              className={`d-block w-100 border-0 px-3 py-2 text-start rounded-top-start-1 rounded-bottom-end-1 bg-success text-white action-button`}
              onClick={() => handleButtonClick('accept')}
            >
              Accept
            </button>
          )}

          {/* Accept dropdown */}
          {activeDropdown === 'accept' && (
            <div className="bg-white shadow-lg border rounded-start-top-1 rounded-end-bottom-1 p-3 position-absolute action-dropdown" 
                 style={{ 
                   top: '100%',
                   right: 0,
                   zIndex: 1000,
                   marginTop: 2 
                 }}>
              <form onSubmit={handleAcceptOffer} className="d-flex flex-column gap-2">
                <div>
                  <button 
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={isLoading || isDisabled}
                  >
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        Submitting...
                      </>
                    ) : isDisabled ? 'Submitted' : 'Accept offer'}
                  </button>
                </div>
                {/* Disclosures section */}
                <div className="d-flex flex-column gap-2" style={{ minWidth: '400px' }}>
                  {formData.disclosures.map((disclosure, index) => (
                    <div key={`disclosure-${index}`} className="d-flex gap-2">
                      <textarea
                        value={disclosure}
                        onChange={(e) => {
                          handleDisclosureChange(index, e.target.value);
                          adjustTextareaHeight(e.target);
                        }}
                        onFocus={(e) => adjustTextareaHeight(e.target)}
                        className="form-control form-control-sm"
                        placeholder={`Disclosure ${index + 1}`}
                        rows={1}
                        style={{ overflow: 'hidden', resize: 'none' }}
                        readOnly={index === 0}
                      />
                      <button
                        type="button"
                        className="btn btn-link text-secondary p-0"
                        onClick={() => handleDeleteDisclosure(index)}
                        style={{ 
                          minWidth: '24px',
                          visibility: formData.disclosures.length > 1 && disclosure.trim() !== '' && index !== 0
                            ? 'visible' 
                            : 'hidden'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          )}
        </span>

        {showAcceptButton && (
          <span>&nbsp;</span>
        )}

        <span className="position-relative">
          <button
            type="button"
            className={`d-block w-100 border-0 px-3 py-2 text-start rounded-top-start-1 rounded-bottom-end-1 bg-warning text-white action-button`}
            onClick={() => handleButtonClick('counteroffer')}
          >
            Counteroffer
          </button>

          {/* Counteroffer dropdown */}
          {activeDropdown === 'counteroffer' && (
            <div className="bg-white shadow-lg border rounded-start-top-1 rounded-end-bottom-1 p-3 position-absolute action-dropdown" 
                 style={{ 
                   top: '100%',
                   right: 0,
                   zIndex: 1000,
                   marginTop: 2 
                 }}>
              <form onSubmit={handleCounterOffer} className="d-flex flex-column gap-2">
                <div className="d-flex gap-2">
                  <div className="d-flex gap-2">
                    <CurrencyInput
                      name="total_amount"
                      value={formData.total_amount}
                      onChange={handleFormChange}
                      className="form-control form-control-sm"
                      style={{ width: '125px' }}
                      placeholder="Total"
                      required
                    />
                    <input
                      type="number"
                      name="payments"
                      value={formData.payments}
                      onChange={handleFormChange}
                      className="form-control form-control-sm"
                      style={{ width: '80px' }}
                      placeholder="Pays"
                      required
                    />
                    <input
                      type="date"
                      name="first_payment_date"
                      value={formData.first_payment_date}
                      onChange={handleFormChange}
                      className="form-control form-control-sm"
                      required
                      min={validFirstPaymentDate}
                    />
                  </div>
                  <button 
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={isLoading || isDisabled}
                  >
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        Submitting...
                      </>
                    ) : isDisabled ? 'Submitted' : 'Submit counteroffer'}
                  </button>
                </div>
                {/* Disclosures section */}
                <div className="d-flex flex-column gap-2" style={{ minWidth: '400px' }}>
                  {formData.disclosures.map((disclosure, index) => (
                    <div key={`disclosure-${index}`} className="d-flex gap-2">
                      <textarea
                        value={disclosure}
                        onChange={(e) => {
                          handleDisclosureChange(index, e.target.value);
                          adjustTextareaHeight(e.target);
                        }}
                        onFocus={(e) => adjustTextareaHeight(e.target)}
                        className="form-control form-control-sm"
                        placeholder={`Disclosure ${index + 1}`}
                        rows={1}
                        style={{ overflow: 'hidden', resize: 'none' }}
                        readOnly={index === 0}
                      />
                      <button
                        type="button"
                        className="btn btn-link text-secondary p-0"
                        onClick={() => handleDeleteDisclosure(index)}
                        style={{ 
                          minWidth: '24px',
                          visibility: formData.disclosures.length > 1 && disclosure.trim() !== '' && index !== 0
                            ? 'visible' 
                            : 'hidden'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default AcceptOrCounteroffer;