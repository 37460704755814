import { Controller } from "@hotwired/stimulus"
import React from 'react'
import { createRoot } from 'react-dom/client'

import PilotPage from '../bundles/Pilot/Page'

export default class extends Controller {
  get props() {
    return JSON.parse(this.data.get("props"))
  }

  connect() {
    this.root = createRoot(this.element)
    this.root.render(<PilotPage props={this.props} />)
  }

  disconnect() {
    this.root.unmount()
  }
}
