import React, { useState, useEffect } from 'react';

const UploadSettlementAgreement = ({ account, onSuccess }) => {
  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [disclosures, setDisclosures] = useState(['This document is a communication from a debt collector.', '']);
  
  // Add unique row identifier
  const rowId = `upload-settlement-row-${account.customer_document.id}`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(`#${rowId} .action-dropdown`);
      const button = document.querySelector(`#${rowId} .action-button`);
      
      if (button?.contains(event.target)) {
        return;
      }
      
      if (!dropdown?.contains(event.target)) {
        setActiveDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [rowId]);

  // Add this new function to handle dropdown visibility
  const ensureDropdownVisible = () => {
    const tableContainer = document.getElementById('table-container');
    const dropdown = document.querySelector(`#${rowId} .action-dropdown`);
    
    if (tableContainer && dropdown) {
      const dropdownRect = dropdown.getBoundingClientRect();
      const containerRect = tableContainer.getBoundingClientRect();
      
      // Calculate if dropdown extends beyond visible area
      const overflowAmount = (dropdownRect.bottom - containerRect.bottom) + 20; // 20px buffer
      
      if (overflowAmount > 0) {
        const scrollOptions = {
          top: tableContainer.scrollTop + overflowAmount,
          behavior: 'smooth'
        };
        tableContainer.scroll(scrollOptions);
      }
    }
  };

  const handleButtonClick = () => {
    setActiveDropdown(!activeDropdown);
    
    if (!activeDropdown) {
      const tableContainer = document.getElementById('table-container');
      if (tableContainer) {
        const scrollOptions = {
          left: tableContainer.scrollWidth,
          behavior: 'smooth'
        };
        setTimeout(() => {
          tableContainer.scroll(scrollOptions);
          ensureDropdownVisible();
        }, 0);
      }
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Update adjustTextareaHeight to include scrolling
  const adjustTextareaHeight = (textarea) => {
    if (!textarea) return;
    const originalHeight = textarea.style.height;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    
    // Only trigger scroll if height actually changed
    if (originalHeight !== textarea.style.height) {
      setTimeout(ensureDropdownVisible, 0);
    }
  };

  // Update handleDisclosureChange to include scrolling
  const handleDisclosureChange = (index, value) => {
    const newDisclosures = [...disclosures];
    newDisclosures[index] = value;
    
    // Add new empty disclosure if typing in last field
    if (index === newDisclosures.length - 1 && value.trim() !== '') {
      newDisclosures.push('');
    }
    
    setDisclosures(newDisclosures);
    
    // Add setTimeout to ensure the DOM has updated
    setTimeout(ensureDropdownVisible, 0);
  };

  // Update handleDeleteDisclosure to include scrolling
  const handleDeleteDisclosure = (index) => {
    const disclosure = disclosures[index];
    if (!disclosure || disclosure.trim() === '') {
      return;
    }
    setDisclosures(disclosures.filter((_, i) => i !== index));
    
    // Ensure dropdown remains visible after deletion
    setTimeout(ensureDropdownVisible, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!confirm("Are you sure you want to submit?")) {
      return;
    }
    
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('customer_document_id', account.customer_document.id);
    
    // Fix: Append each disclosure separately
    const filteredDisclosures = disclosures.filter(d => d.trim() !== '');
    filteredDisclosures.forEach(disclosure => {
      formData.append('disclosures[]', disclosure);
    });

    try {
      const response = await fetch('/collectors/api/upload_settlement_agreement', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      setIsUploaded(true);
      onSuccess();
    } catch (error) {
      console.error('Error uploading settlement agreement:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isUploaded) {
    return (
      <div className="d-flex gap-2">
        <input type="submit" value="Agreement Uploaded" className="btn btn-primary btn-sm" disabled />
      </div>
    );
  }

  return (
    <div className="position-relative" id={rowId}>
      <div className="d-flex align-items-start">
        <span className="position-relative">
          <button
            type="button"
            className={`d-block w-100 border-0 px-3 py-2 text-start rounded-top-start-1 rounded-bottom-end-1 bg-warning text-white action-button`}
            onClick={handleButtonClick}
            style={{ whiteSpace: 'nowrap' }}
          >
            Upload Settlement Agreement
          </button>

          {activeDropdown && (
            <div className="bg-white shadow-lg border rounded-start-top-1 rounded-end-bottom-1 p-3 position-absolute action-dropdown" 
                 style={{ 
                   top: '100%',
                   right: 0,
                   zIndex: 1000,
                   marginTop: 2 
                 }}>
              <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
                <div className="d-flex gap-2">
                  <div className="custom-file-input d-flex align-items-center">
                    <input
                      type="file"
                      name="settlement_agreement"
                      accept="application/pdf"
                      required
                      className="form-control form-control-sm"
                      onChange={handleFileChange}
                      disabled={isSubmitting}
                      style={{ width: 'initial' }}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={!file || isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        Submitting...
                      </>
                    ) : 'Submit'}
                  </button>
                </div>

                {/* Updated Disclosures section */}
                <div className="d-flex flex-column gap-2" style={{ minWidth: '400px' }}>
                  {disclosures.map((disclosure, index) => (
                    <div key={`disclosure-${index}`} className="d-flex gap-2">
                      <textarea
                        value={disclosure}
                        onChange={(e) => {
                          handleDisclosureChange(index, e.target.value);
                          adjustTextareaHeight(e.target);
                        }}
                        onFocus={(e) => adjustTextareaHeight(e.target)}
                        className="form-control form-control-sm"
                        placeholder={`Disclosure ${index + 1}`}
                        rows={1}
                        style={{ overflow: 'hidden', resize: 'none' }}
                      />
                      <button
                        type="button"
                        className="btn btn-link text-secondary p-0"
                        onClick={() => handleDeleteDisclosure(index)}
                        style={{ 
                          minWidth: '24px',
                          visibility: disclosures.length > 1 && disclosure.trim() !== '' 
                            ? 'visible' 
                            : 'hidden'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default UploadSettlementAgreement;