import * as React from 'react'
import { createConsumer } from "@rails/actioncable"

import { cableUrl } from "../../utils/channels"
import { camelKeys } from "../../utils/json"
import { ChannelState, CompletionsChannelPayload, CompletionsChannelPayloadType, CompletionDelta, Completion } from "../../types"
import { appendAssistantCompletionDelta, updateChannelState, upsertCompletion } from './slice'
import { useAppDispatch } from '../../hooks'

const CHANNEL_NAME = "PilotCompletionsChannel"

type Props = {
  children: React.ReactNode;
}

const Channel = ({ children }: Props): React.ReactElement => {
  const dispatch = useAppDispatch()

  const handlePayloadReceived = (payload: CompletionsChannelPayload) => {
    switch (payload.type) {
      case CompletionsChannelPayloadType.Delta:
        dispatch(appendAssistantCompletionDelta(payload.data as CompletionDelta))
        break
      case CompletionsChannelPayloadType.Object:
        dispatch(upsertCompletion(payload.data as Completion))
        break
    }
  }

  const createChannel = () => {
    return createConsumer(cableUrl()).subscriptions.create({
      channel: CHANNEL_NAME,
    }, {
      connected: handleConnected,
      disconnected: handleDisconnected,
      received: handleReceived,
    })
  }

  const handleConnected = () => dispatch(updateChannelState(ChannelState.Connected))
  const handleDisconnected = () => dispatch(updateChannelState(ChannelState.Disconnected))
  const handleReceived = (payload: any) => handlePayloadReceived(camelKeys(payload))

  React.useEffect(() => {
    const channel = createChannel()
    return () => channel.unsubscribe()
  }, [])

  return (<>{children}</>)
}

export default Channel
