document.addEventListener("DOMContentLoaded", function() {
    if (document.getElementById('document-editor')) {
        const quill = new Quill('#document-editor', {
            theme: 'snow',
            modules: {
                toolbar: { container: '#toolbar' }
            }
        });

        document.getElementById('undo').onclick = function() {
            quill.history.undo();
        };
        document.getElementById('redo').onclick = function() {
            quill.history.redo();
        };

        const savedContent = document.querySelector('#hidden-content').value;
        if (savedContent) {
            quill.root.innerHTML = savedContent;
        }

        document.getElementById('api-save-button').addEventListener('click', function(e) {
            e.preventDefault();

            const successAlert = document.getElementById('save-success-alert');
            const errorAlert = document.getElementById('save-error-alert');
            
            const content = quill.root.innerHTML;
            const form = document.querySelector('#editor-form');
            const documentId = form.action.match(/document_generations\/(\d+)/)?.[1];
        
            fetch(`/steps_api/save_editor_content/${documentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({ content: content })
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    successAlert.style.display = 'block';
                    setTimeout(() => {
                      successAlert.style.display = 'none';
                    }, 3000);
                } else {
                    errorAlert.style.display = 'block';
                    setTimeout(() => {
                      errorAlert.style.display = 'none';
                    }, 3000);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Show error message
                const saveStatus = document.getElementById('save-status');
                saveStatus.textContent = 'Error saving!';
                saveStatus.classList.remove('text-success');
                saveStatus.classList.add('text-danger');
            });
        });

        document.getElementById('preview-button').addEventListener('click', function() {
            // wrap with ql-editor to reproduce exactly what we see in the editor
            const quillHtmlContent = `<div class='ql-container'><div class='ql-editor'>${quill.root.innerHTML}</div></div>`;

            html2pdf().set({
                margin: 1,
                filename: 'document-preview.pdf',
                image: { type: 'jpeg', quality: 0.8 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' } // 8.5 x 11 inches
            }).from(quillHtmlContent).save();
        });

        document.getElementById('solosuit-close-modal').onclick = function() {
            document.getElementById('export-pdf-modal').style.display = "none";
        };

        window.onclick = function(event) {
            if (event.target === document.getElementById('export-pdf-modal')) {
                document.getElementById('export-pdf-modal').style.display = "none";
            }
        };
    }
});