import React from 'react'

import { addQueryParams } from '../../utils/url'
import { snakeKeys } from '../../utils/json'

const UTM_PARAMS = {
  utm_source: "solo",
  utm_medium: "pilot",
}

const SOLO_PRODUCT_MAPPINGS = {
  "https://www.solosuit.com/debt_answer": "https://www.solosuit.com/customer_documents?document_name=debt_answer",
  "https://www.solosuit.com/solosettle": "https://www.solosuit.com/customer_documents?document_name=solosettle",
  "https://www.solosuit.com/document_generation": "https://www.solosuit.com/customer_documents?document_name=document_generation",
}

type Params = {
  children: React.ReactNode;
  completionId: number;
  href: string;
}

const soloProductUrl = (url: string) => {
  return Object.keys(SOLO_PRODUCT_MAPPINGS).includes(url)
}

function AnchorRenderer({ children, completionId, href }: Params) {
  if (soloProductUrl(href)) {
    return (
      <a
        href={addQueryParams(SOLO_PRODUCT_MAPPINGS[href], UTM_PARAMS)}
        data-method="post"
        data-controller="pilot--anchor"
        data-pilot--anchor-props={JSON.stringify(snakeKeys({ pilotCompletionId: completionId }))}
        target="_blank"
      >
        {children}
      </a>
    )
  } else {
    return (
      <a
        href={addQueryParams(href, UTM_PARAMS)}
        data-controller="pilot--anchor"
        data-pilot--anchor-props={JSON.stringify(snakeKeys({ pilotCompletionId: completionId }))}
        target="_blank"
      >
        {children}
      </a>
    )
  }
}

export default AnchorRenderer
