import React, { useState, useEffect } from 'react';
import PaymentPlanSummary from './PaymentPlanSummary';
import { Card, CardContent, CardTitle, CardDescription } from '../ui/Card';
import CallToActionCards from './CallToActionCards';

const Dashboard = () => {
  const [cases, setCases] = useState([]);
  const [unorganizedDocuments, setUnorganizedDocuments] = useState([]);
  const [flashNotice, setFlashNotice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/my_dashboard.json', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'same-origin'
        });
        const data = await response.json();
        setCases(data.cases);
        setUnorganizedDocuments(data.unorganizedDocuments);
        setFlashNotice(data.flash_notice);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const CaseHeader = ({ info }) => {
    if (info.plaintiff_name && info.lawsuit_amount) {
      return (
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h2 className="mb-0">{info.plaintiff_name}</h2>
          <h2 className="mb-0 text-primary">{formatMoney(info.lawsuit_amount)}</h2>
        </div>
      );
    } else if (info.civil_number) {
      return <h2 className="mb-0">Case {info.civil_number}</h2>;
    } else {
      return <h2 className="mb-0">Case #{info.id}</h2>;
    }
  };

  const DocumentCard = ({ document }) => (
    <div className="mb-6">
      <div className="d-flex flex-column">
        <a 
          href={document.is_completed ? `/my_dashboard/download?id=${document.id}` : document.resume_url}
          className="text-decoration-none mb-2"
          data-remote="false"
          data-turbolinks="false"
        >
          <h3 className="text-primary mb-0">{document.title}</h3>
        </a>
        <p className="mb-6 fs-6 text-body">
          Started on {formatDate(document.created_at)}
        </p>
        <div className="d-flex flex-column mb-4">
          <h3 className="mb-2">Status</h3>
          <div className="d-inline-flex">
            <a 
              href={document.is_completed ? `/my_dashboard/download?id=${document.id}` : document.resume_url}
              className="text-decoration-none"
              data-remote="false"
              data-turbolinks="false"
            >
              <span className={`badge rounded-pill border border-${document.status_color} text-${document.status_color} bg-transparent px-3 py-2`}>
                {document.status}
              </span>
            </a>
          </div>
        </div>
        <div className="d-flex flex-column">
          <h3 className="mb-2">Progress Checklist</h3>
          <div className="accordion inverted-accordion" id={`accordion-${document.id}`}>
            {document.sectioned_steps?.map((section, sectionIndex) => (
              <div className="accordion-item" key={sectionIndex}>
                <h2 className="accordion-header d-inline-flex" id={`heading-${sectionIndex}-${document.id}`}>
                  <button 
                    className="accordion-button" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#collapse-${sectionIndex}-${document.id}`} 
                    aria-expanded="true" 
                    aria-controls={`collapse-${sectionIndex}-${document.id}`}
                  >
                    {section.name}
                  </button>
                </h2>
                <div 
                  id={`collapse-${sectionIndex}-${document.id}`} 
                  className="accordion-collapse collapse show" 
                  aria-labelledby={`heading-${sectionIndex}-${document.id}`}
                >
                  {section.steps.map((step, stepIndex) => (
                    <div 
                      key={stepIndex} 
                      className={`accordion-body ${step.current ? 'text-primary' : !step.completed ? 'text-muted' : ''}`}
                    >
                      {step.title}{step.value ? `: ${step.value}` : ''}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mb-13">
      <div className="row">
        <div className="col">
          <h1 className="mb-5 font-serif">Dashboard</h1>
          {flashNotice && (
            <div className="alert alert-info">{flashNotice}</div>
          )}
        </div>
      </div>

      <CallToActionCards />

      <div className="row mb-8">
        {loading ? (
          <div className="col">
            <Card>
              <CardContent>
                <div className="placeholder-glow">
                  <div className="d-flex align-items-center mb-3">
                    <span className="placeholder col-4 mb-0"></span>
                  </div>
                  <div className="mb-3">
                    <span className="placeholder col-6 d-block mb-2"></span>
                    <span className="placeholder col-8 d-block"></span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div className="col">
            {cases.map((consumer_case) => (
              <div key={consumer_case.id} className="mb-6">
                <div className="accordion shadow-none" id={`accordion-${consumer_case.id}`}>
                  <div className="accordion-item d-flex flex-column gap-1 shadow-none bg-transparent">
                    <button 
                      className="accordion-button accordion-button-left accordion-reverse shadow-none bg-transparent p-0 border-0 text-decoration-none d-flex align-items-center w-100 mb-2" 
                      type="button" 
                      data-bs-toggle="collapse" 
                      data-bs-target={`#collapse-${consumer_case.id}`} 
                      aria-expanded="true" 
                      aria-controls={`collapse-${consumer_case.id}`}
                    >
                      <CaseHeader info={consumer_case.info} />
                    </button>
                    <div id={`collapse-${consumer_case.id}`} className="accordion-collapse collapse show" aria-labelledby={`heading-${consumer_case.id}`} data-bs-parent={`#accordion-${consumer_case.id}`}>
                      <div className="accordion-body p-0 d-flex flex-column gap-4">
                        {consumer_case.has_payment_plan && (
                          <Card>
                            <CardContent>
                              {consumer_case.documents.map(doc => 
                                doc.payment_plan && <PaymentPlanSummary key={doc.id} paymentPlan={doc.payment_plan} />
                              )}
                            </CardContent>
                          </Card>
                        )}
                        {consumer_case.documents.map((document) => (
                          <Card key={document.id}>
                            <CardContent>
                              <DocumentCard document={document} />
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {unorganizedDocuments.length > 0 && (
              <Card>
                <CardContent>
                  <CardTitle><h2>Documents In Progress</h2></CardTitle>
                  <div>
                    {unorganizedDocuments.map((document) => (
                      <DocumentCard key={document.id} document={document} />
                    ))}
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </div>

      <div className="row" id="create">
        <div className="col">
          <Card>
            <CardContent>
              <CardTitle><h2>Create a New Document</h2></CardTitle>
              <CardDescription className="d-flex flex-column gap-2">
                <span>
                  <a href="/customer_documents?document_name=debt_answer" className="h4 text-primary text-decoration-none d-block mb-0" data-method="post" rel="nofollow">
                    Debt Answer
                  </a>
                  <p className='mb-0'>Use this document to respond to a debt collection lawsuit.</p>
                </span>

                <span>
                  <a href="/customer_documents?document_name=solosettle" className="h4 text-primary text-decoration-none d-block mb-0" data-method="post" rel="nofollow">
                    SoloSettle
                  </a>
                  <p className='mb-0'>Use this to settle a debt.</p>
                </span>

                <span>
                  <a href="/customer_documents?document_name=document_generation" className="h4 text-primary text-decoration-none d-block mb-0" data-method="post" rel="nofollow">
                    Document Generation
                  </a>
                  <p className='mb-0'>Generate documents with AI.</p>
                </span>
              </CardDescription>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 