export enum AppType {
  Card = "CARD",
}

export enum ChannelState {
  Connected = "CONNECTED",
  Disconnected = "DISCONNECTED",
}

export interface Completion {
  assistantResponse?: string;
  createdAt: number;
  id: number;
  state: CompletionState;
  userRequest: string;
}

export interface CompletionsChannelPayload {
  data: Completion | CompletionDelta;
  type: CompletionsChannelPayloadType;
}

export enum CompletionsChannelPayloadType {
  Delta = "delta",
  Object = "object"
}

export interface CompletionDelta {
  id: number;
  assistantResponseDelta: string;
}

export enum CompletionRole {
  Assistant = "assistant",
  User = "user",
}

export enum CompletionState {
  Processing = "processing",
  Successful = "successful",
  Failed = "failed",
}
