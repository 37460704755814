import React, { useState, useEffect } from 'react';
import { formatDollars, extractCentsFromDollarString } from '../../../other/money';
import AccountDetails from './AccountDetails';

import AcceptOrCounteroffer from './actions/AcceptOrCounteroffer';
import SendPaymentDetails from './actions/SendPaymentDetails';
import UploadSettlementAgreement from './actions/UploadSettlementAgreement';

const AccountRow = ({ validFirstPaymentDate, account, columnsToShow }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentAction, setCurrentAction] = useState(null);
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    setCurrentAction(account.current_action);
    setIsLoading(false);
  }, [account.current_action]);

  useEffect(() => {
    setIsExpanded(false);
  }, [account.current_action]);

  function handleRefreshCurrentAction() {
    setIsLoading(true);
    fetch(`/collectors/api/get_current_action?customer_document_id=${account.customer_document.id}`)
      .then(response => response.json())
      .then(data => {
        setCurrentAction(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleArchive = () => {
    if (window.confirm('Are you sure you want to archive this account?')) {
      setIsLoading(true);
      fetch(`/collectors/api/archive_document`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          customer_document_id: account.customer_document.id
        })
      })
        .then(response => {
          if (!response.ok) throw new Error('Archive failed');
          setIsArchived(true);
          return response.json();
        })
        .catch(error => {
          alert('Failed to archive document');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <tr 
        id={`account_${account.id}`} 
        style={{ 
          cursor: 'pointer',
          display: isArchived ? 'none' : 'table-row'
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {columnsToShow.includes('Name') && (
          <td>
            <button className="btn btn-link p-0">
              {account.customer_name || 'n/a'}
            </button>
          </td>
        )}
        {columnsToShow.includes('Birthdate') && (
          <td className="text-center">
            {account.birthdate && new Date(account.birthdate).toLocaleDateString()}
          </td>
        )}
        {columnsToShow.includes('Reference') && (
          <td className="text-center">
            {account.civil_number || 'n/a'}
          </td>
        )}
        {columnsToShow.includes('Lawsuit State') && (
          <td className="text-center">
            {account.lawsuit_state || 'n/a'}
          </td>
        )}
        {columnsToShow.includes('Plaintiff') && (
          <td className="text-center">
            {account.plaintiff_name || 'n/a'}
          </td>
        )}
        {columnsToShow.includes('Amount') && (
          <td className="text-center">
            {account.lawsuit_amount && formatDollars(account.lawsuit_amount)}
          </td>
        )}
        {columnsToShow.includes('Status') && (
          <td className="text-center" style={{ 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            whiteSpace: 'nowrap' 
          }}>
            {account.last_action || '-'}
          </td>
        )}
        {columnsToShow.includes('Status Date') && (
          <td className="text-center">
            {account.latest_event?.created_at && new Date(account.latest_event.created_at).toLocaleDateString()}
          </td>
        )}
        {columnsToShow.includes('Offer Amount') && (
          <td className="text-center">
            {account.latest_historical_offer?.total_amount_cents && 
              formatDollars(account.latest_historical_offer.total_amount_cents)}
          </td>
        )}
        {columnsToShow.includes('# Pays') && (
          <td className="text-center">
            {account.latest_historical_offer?.payments || '-'}
          </td>
        )}
        {columnsToShow.includes('%') && (
          <td className="text-center">
            {account.latest_historical_offer?.total_amount_cents && account.lawsuit_amount && (
              `${Math.round((account.latest_historical_offer.total_amount_cents / extractCentsFromDollarString(account.lawsuit_amount)) * 100)}%`
              )}
          </td>
        )}
        {columnsToShow.includes('First Payment') && (
          <td style={{ whiteSpace: 'nowrap' }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              {account.latest_historical_offer?.first_payment_date < validFirstPaymentDate && ["offer", "counteroffer", "sent_confusing_response_to_offer"].includes(account.latest_event?.action) && (
                <div className="tooltip-container" style={{ position: 'absolute', whiteSpace: 'normal' }}>
                  <div className="tooltip-trigger">!</div>
                  <div className="tooltip-content">
                    First payment date must be on or after {account.latest_historical_offer?.first_payment_date}. You'll need to counteroffer with a new payment date.
                  </div>
                </div>
              )}
              <span style={{ marginLeft: '25px' }}>
                {account.latest_historical_offer?.first_payment_date && 
                  new Date(account.latest_historical_offer.first_payment_date + 'T00:00:00').toLocaleDateString('en-US', {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric'
                  })
                }
              </span>
            </div>
          </td>
        )}
        <td>
          <div className="dropdown">
            <button 
              className="btn btn-link btn-sm p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => e.stopPropagation()}
              style={{ fontSize: '1.5rem' }}
            >
              ⋮
            </button>
            <ul className="dropdown-menu dropdown-menu-end" style={{ padding: 2, width: 'auto', minWidth: 'auto' }}>
              <li>
                <button 
                  className="dropdown-item py-1 px-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArchive();
                  }}
                  style={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
                >
                  Archive
                </button>
              </li>
            </ul>
          </div>
        </td>
        {isLoading && <td><div className="spinner-border spinner-border-sm" role="status"></div></td>}
        {!isLoading && <td>
          {currentAction.type == "accept_or_counteroffer" && <div onClick={(e) => e.stopPropagation()}><AcceptOrCounteroffer onSuccess={handleRefreshCurrentAction} account={account} validFirstPaymentDate={validFirstPaymentDate} /></div>}
          {currentAction.type == "waiting_for_customer" && <div>Waiting on customer response to counteroffer.</div>}
          
          {currentAction.type == "request_payment_details" && <div onClick={(e) => e.stopPropagation()}><SendPaymentDetails onSuccess={handleRefreshCurrentAction} account={account} /></div>}
          {currentAction.type == "request_settlement_agreement" && <div onClick={(e) => e.stopPropagation()}><UploadSettlementAgreement onSuccess={handleRefreshCurrentAction} account={account} /></div>}

          {currentAction.type == "waiting_for_signed_settlement_agreement" && <div>Waiting for signed settlement agreement.</div>}
          {currentAction.type == "waiting_for_payment" && <div>Waiting for first payment.</div>}
          
          {/* Payment status */}
          {currentAction.type == "payment_setup_complete" && <div>Consumer set up auto-pay but hasn't made a payment yet.</div>}
          {currentAction.type == "first_payment_made" && <div>Consumer made their first payment on {new Date(currentAction.params.latest_payment_intent.created_at).toLocaleDateString()}.</div>}
        </td>}
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={10} className="p-0">
            <AccountDetails account={account} />
          </td>
        </tr>
      )}
    </>
  );
};

export default AccountRow;