document.addEventListener('DOMContentLoaded', function() {
  const fullSsnGroup = document.querySelector('input[name*="social_security_number"]:not([name*="last_four"])').closest('.form-group');
  const lastFourGroup = document.querySelector('input[name*="social_security_number_last_four"]').closest('.form-group');
  const toggleLink = document.querySelector('.ssn-toggle');
  
  if (!fullSsnGroup || !lastFourGroup || !toggleLink) return;
  
  const toggleText = toggleLink.querySelector('.toggle-text');
  const fullSsnInput = fullSsnGroup.querySelector('input');
  const lastFourInput = lastFourGroup.querySelector('input');
  
  // Initialize state based on which field has a value
  let showingFullSsn = !(lastFourInput.value && !fullSsnInput.value);
  
  // Set initial visibility
  if (!showingFullSsn) {
    fullSsnGroup.classList.add('d-none');
    lastFourGroup.classList.remove('d-none');
    fullSsnInput.required = false;
    lastFourInput.required = true;
    toggleText.textContent = 'I want to enter the full SSN.';
  }

  toggleLink.addEventListener('click', function(e) {
    e.preventDefault();
    
    if (showingFullSsn) {
      fullSsnGroup.classList.add('d-none');
      lastFourGroup.classList.remove('d-none');
      fullSsnInput.required = false;
      lastFourInput.required = true;
      toggleText.textContent = 'I want to enter the full SSN.';
    } else {
      fullSsnGroup.classList.remove('d-none');
      lastFourGroup.classList.add('d-none');
      fullSsnInput.required = true;
      lastFourInput.required = false;
      toggleText.textContent = 'I only want to enter the last four digits.';
    }
    
    showingFullSsn = !showingFullSsn;
  });
}); 