import React, { useEffect, useState } from 'react';
import Layout from './Layout';

import { formatDollars } from '../../other/money';

import Filters from './accounts/Filters';
import AccountsTable from './accounts/AccountsTable';

const FILTERS_NOT_IN_URL_BAR = ['columns'];
const DEFAULT_SHOWN_COLUMNS = ['Name', 'Reference', 'Amount', 'Status', 'Status Date', 'Offer Amount', '# Pays', '%', 'First Payment']

const Accounts = () => {
  const [basicStats, setBasicStats] = useState(null);
  const [accountsData, setAccountsData] = useState(null);
  const [numPages, setNumPages] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return parseInt(urlParams.get('num_pages')) || 1;
  });

  const [selectedFilterValues, setSelectedFilterValues] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialValues = {
      columns: DEFAULT_SHOWN_COLUMNS // Set default columns
    };
    
    // First check for search parameter specifically
    const searchValue = urlParams.get('filters[search]');
    if (searchValue) {
      initialValues.search = searchValue;
    }
    
    // Extract all other filter parameters from URL
    for (const [key, value] of urlParams.entries()) {
      // Only process parameters that start with "filters["
      if (key.startsWith('filters[') && key.endsWith(']')) {
        // Extract the filter name from the key
        const matches = key.match(/filters\[([^\]]+)\]\[([^\]]+)\]/);
        if (!matches) continue;
        
        const [, filterName, arrayIndexOrKey] = matches;
        
        // Skip if this is the search parameter (already handled)
        if (filterName === 'search') continue;
        
        // Initialize the filter if it doesn't exist
        if (!initialValues[filterName]) {
          initialValues[filterName] = Number.isInteger(parseInt(arrayIndexOrKey)) ? [] : {};
        }
        
        // Handle array-type filters (numeric indices)
        if (Number.isInteger(parseInt(arrayIndexOrKey))) {
          if (Array.isArray(initialValues[filterName])) {
            initialValues[filterName].push(value);
          }
        } 
        // Handle object-type filters (named keys like 'min'/'max')
        else {
          if (!Array.isArray(initialValues[filterName])) {
            initialValues[filterName][arrayIndexOrKey] = value;
          }
        }
      }
    }
    
    return initialValues;
  });

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('num_pages', numPages);
    
    Object.entries(selectedFilterValues)
      .filter(([key]) => key !== 'columns') // Exclude columns from URL parameters
      .forEach(([key, value]) => {
        if (typeof value === 'object') {
          // Handle range filters
          Object.entries(value).forEach(([rangeKey, rangeValue]) => {
            urlParams.set(`filters[${key}][${rangeKey}]`, rangeValue);
          });
        } else {
          // Handle regular filters
          urlParams.set(`filters[${key}]`, value);
        }
      });
    
    const newUrl = `${window.location.pathname}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
    window.history.pushState({}, '', newUrl);
  }, [selectedFilterValues, numPages]);

  const fetchAccountData = async () => {
    const urlParams = new URLSearchParams();
    urlParams.set('num_pages', numPages);
    
    const filtersString = Object.entries(selectedFilterValues)
      .filter(([key]) => !FILTERS_NOT_IN_URL_BAR.includes(key))
      .map(([key, value]) => {
        if (typeof value === 'object') {
          // Handle range filters
          return Object.entries(value)
            .map(([rangeKey, rangeValue]) => `filters[${key}][${rangeKey}]=${rangeValue}`)
            .join('&');
        }
        // Handle regular filters
        return `filters[${key}]=${value}`;
      }).join('&');
    
    const accountsDataResponse = await fetch(`/collectors/api/apply_filters?${urlParams.toString()}&${filtersString}`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const accountsDataJson = await accountsDataResponse.json();
    setAccountsData(accountsDataJson);
  };

  const fetchFilterValues = async () => {
    const filterValuesResponse = await fetch(`/collectors/api/filter_values`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const filterValuesJson = await filterValuesResponse.json();
    setFilters(filterValuesJson.filters);
  };

  const fetchBasicStats = async () => {
    const basicStatsResponse = await fetch(`/collectors/api/basic_stats`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const basicStatsJson = await basicStatsResponse.json();
    setBasicStats(basicStatsJson);
  };

  useEffect(() => {
    setAccountsData(null);
    fetchAccountData();
  }, [numPages, selectedFilterValues]);

  useEffect(() => {
    fetchBasicStats();
    fetchFilterValues();
  }, []);

  const handleFilterChange = (key, value, rangeKey = null) => {
    const clonedFilterValues = JSON.parse(JSON.stringify(selectedFilterValues));
    if (rangeKey) {
      // Handle range filters
      clonedFilterValues[key] = clonedFilterValues[key] || {};
      clonedFilterValues[key][rangeKey] = value;
    } else {
      // Handle regular filters
      clonedFilterValues[key] = value;
    }
    setSelectedFilterValues(clonedFilterValues);
  };

  const handleFilterRemove = (key) => {
    const clonedFilterValues = { ...selectedFilterValues };
    delete clonedFilterValues[key];
    setSelectedFilterValues(clonedFilterValues);
  };

  const handleClearAll = () => {
    window.location.href = '/collectors/accounts';
  };

  return (
    <Layout>
      <div>
        {basicStats && (
          <>
            <h2 className="font-serif display-4 mb-2">Hello {basicStats.law_firm.name},</h2>
            <p className="lead text-muted mb-0">
              Your firm has a total balance of <strong>{formatDollars(basicStats.total_balance.cents)}</strong> on SoloSettle.
            </p>
            <p className="lead text-muted mb-0">
              Consumers are notified that each communication is from a debt collector.
            </p>
            <br/>

            <h1 className="display-3 mb-6">Accounts</h1>

            <Filters 
              filters={filters} 
              filterValues={selectedFilterValues} 
              setFilter={handleFilterChange} 
              removeFilter={handleFilterRemove}
              clearAll={handleClearAll}
            />
          </>
        )}

        {basicStats && !accountsData && (
          <div className="spinner-border" role="status"></div>
        )}
        {accountsData && (
          <>
            <AccountsTable 
              selectedFilterValues={selectedFilterValues}
              accounts={accountsData.customer_info}
              setAccounts={accounts => {
                const clonedAccounts = JSON.parse(JSON.stringify(accountsData));
                clonedAccounts.customer_info = accounts;
                setAccountsData(clonedAccounts);
              }}
              hasPaymentDetails={accountsData.has_payment_details}
              validFirstPaymentDate={accountsData.valid_first_payment_date}
            />

            {accountsData.more_pages && (
              <div className="text-center mt-4">
                <button onClick={() => setNumPages(prev => prev + 1)} className="btn btn-primary">
                  Load More
                </button>
              </div>
            )}

            <div className="mt-4 mb-4">
              Showing {accountsData.customer_info.length} out of {accountsData.total_count} matching accounts
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Accounts;