import React from 'react';

const Chevron = () => (
  <svg 
    width="14" 
    height="10" 
    viewBox="0 0 14 10" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className="chevron-icon"
  >
    <path 
      d="M13 1.6875L7 7.6875L1 1.6875" 
      stroke='#9E9E9E'
      strokeWidth="2"
    />
  </svg>
);

const RangeFilter = ({ name, values, currentValue, onChange, onRemove, filterKey, unit }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [localMin, setLocalMin] = React.useState(currentValue?.min || '');
  const [localMax, setLocalMax] = React.useState(currentValue?.max || '');

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSave = () => {
    onChange(filterKey, { min: localMin, max: localMax });
    setIsOpen(false);
  };

  const handleClear = () => {
    setLocalMin('');
    setLocalMax('');
    onRemove(filterKey);
    setIsOpen(false);
  };

  const formatValue = (value) => {
    if (!value) return '∞';
    if (unit.value === 'DATE') {
      const date = new Date(value * 1000);
      const tzOffset = date.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(date.getTime() + tzOffset);
      return adjustedDate.toLocaleDateString();
    }
    return unit.location === 'before' ? `${unit.value}${value}` : `${value}${unit.value}`;
  };

  const renderInput = (value, setValue, placeholder) => {
    if (unit.value === 'DATE') {
      return (
        <input
          type="date"
          className="form-control"
          value={value ? new Date(value * 1000).toISOString().slice(0, 10) : ''}
          onChange={(e) => {
            const selectedDate = new Date(e.target.value + 'T00:00:00.000Z');
            const timestamp = Math.floor(selectedDate.getTime() / 1000);
            setValue(timestamp);
          }}
          placeholder={placeholder}
          min={values?.min ? new Date(values.min * 1000).toISOString().slice(0, 10) : undefined}
          max={values?.max ? new Date(values.max * 1000).toISOString().slice(0, 10) : undefined}
        />
      );
    }

    return (
      <div className="input-group">
        {unit.location === 'before' && <span className="input-group-text">{unit.value}</span>}
        <input
          type="number"
          className="form-control"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        />
        {unit.location === 'after' && <span className="input-group-text">{unit.value}</span>}
      </div>
    );
  };

  const displayValue = currentValue 
    ? `${formatValue(currentValue.min)} - ${formatValue(currentValue.max)}`
    : name;

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button 
        className={`btn btn-sm dropdown-toggle ${currentValue ? 'btn-primary' : 'btn-outline-secondary'}`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-label={name}
      >
        <span className="d-flex align-items-center gap-2">
          {displayValue}
          <Chevron />
        </span>
      </button>
      
      {isOpen && (
        <div className="dropdown-menu show p-3 mt-1" style={{ minWidth: '200px' }}>
          <div className="mb-2">
            <label className="form-label">Min</label>
            {renderInput(
              localMin,
              setLocalMin,
              values?.min ? 
                unit.value === 'DATE' ? 
                  new Date(values.min).toISOString().split('T')[0] : 
                  values.min.toLocaleString() 
                : 'Min'
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Max</label>
            {renderInput(
              localMax,
              setLocalMax,
              values?.max ? 
                unit.value === 'DATE' ? 
                  new Date(values.max).toISOString().split('T')[0] : 
                  values.max.toLocaleString() 
                : 'Max'
            )}
          </div>
          <div className="d-flex gap-2">
            <button 
              className="btn btn-outline-secondary btn-sm" 
              onClick={handleClear}
            >
              Clear
            </button>
            <button 
              className="btn btn-primary btn-sm ms-auto" 
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const EnumFilter = ({ name, values, currentValue=[], onChange, onRemove, filterKey }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [localSelections, setLocalSelections] = React.useState(currentValue);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setLocalSelections(currentValue);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentValue]);

  const handleToggle = (val) => {
    setLocalSelections(prev => 
      prev.includes(val) 
        ? prev.filter(v => v !== val)
        : [...prev, val]
    );
  };

  const handleSave = () => {
    onChange(filterKey, localSelections);
    setIsOpen(false);
  };

  const handleClear = () => {
    setLocalSelections([]);
    onRemove(filterKey);
    setIsOpen(false);
  };

  const displayValue = currentValue.length > 0 ? `${name} (${currentValue.length})` : name;

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button 
        className={`btn btn-sm dropdown-toggle ${currentValue.length > 0 ? 'btn-primary' : 'btn-outline-secondary'}`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-label={name}
      >
        <span className="d-flex align-items-center gap-2">
          {displayValue}
          <Chevron />
        </span>
      </button>
      
      <div className={`dropdown-menu mt-1 ${isOpen ? 'show' : ''}`} style={{ minWidth: '200px', padding: '10px' }}>
        <div className="d-flex flex-column" style={{ maxHeight: '300px' }}>
          <div className="overflow-auto flex-grow-1 p-3">
            <ul className="list-unstyled mb-0">
              {values?.map((val) => (
                <li key={val}>
                  <div 
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => handleToggle(val)}
                    role="button"
                  >
                    <div className="form-check mb-0 mt-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={localSelections.includes(val)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <span>{val}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex gap-2 p-2 border-top">
            <button 
              className="btn btn-outline-secondary btn-sm" 
              onClick={handleClear}
            >
              Clear
            </button>
            <button 
              className="btn btn-primary btn-sm ms-auto" 
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TextFilter = ({ name, currentValue='', onChange, onRemove, filterKey }) => {
  const [localValue, setLocalValue] = React.useState(currentValue);
  const debounceTimeout = React.useRef(null);

  React.useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const handleInputChange = (value) => {
    setLocalValue(value);
    
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (value === '') {
        onRemove(filterKey);
      } else {
        onChange(filterKey, value);
      }
    }, 250);
  };

  const handleClear = () => {
    setLocalValue('');
    onRemove(filterKey);
  };

  return (
    <div className="position-relative">
      <input
        type="text"
        className="form-control form-control-sm"
        value={localValue}
        onChange={(e) => handleInputChange(e.target.value)}
        placeholder={name}
      />
      {localValue && (
        <button
          className="btn btn-link btn-sm position-absolute top-50 end-0 translate-middle-y pe-2"
          onClick={handleClear}
          style={{ 
            fontSize: 20,
            border: 'none',
            background: 'none',
            padding: '0',
            color: '#6c757d'
          }}
          aria-label="Clear search"
        >
          ×
        </button>
      )}
    </div>
  );
};

/**
 * 
 * @param {*} filters: example: [{ name: 'State', key: 'state', type: 'enum', values: ['CA', 'NY', 'TX'] }]
 * @param {*} setFilter: setFilter(key, newValue)
 * @returns 
 */
const Filters = ({ filters=[], filterValues={}, setFilter, removeFilter, clearAll }) => {
  const hasActiveFilters = Object.keys(filterValues).length > 0;

  return (
    <>
      <div className="mb-2 d-flex flex-wrap gap-3" role="group" aria-label="Filters">
        {filters.map((filter) => {
          switch (filter.type) {
            case 'enum':
              return (
                <EnumFilter 
                  key={filter.key} 
                  filterKey={filter.key} 
                  name={filter.name} 
                  values={filter.values} 
                  currentValue={filterValues[filter.key]} 
                  onChange={setFilter} 
                  onRemove={removeFilter}
                />
              );
            case 'range':
              return (
                <RangeFilter
                  key={filter.key}
                  filterKey={filter.key}
                  name={filter.name}
                  values={filter.values}
                  currentValue={filterValues[filter.key]}
                  onChange={setFilter}
                  onRemove={removeFilter}
                  unit={filter.unit}
                />
              );
            case 'text':
              return (
                <TextFilter
                  key={filter.key}
                  filterKey={filter.key}
                  name={filter.name}
                  currentValue={filterValues[filter.key]}
                  onChange={setFilter}
                  onRemove={removeFilter}
                />
              );
            default:
              return null;
          }
        })}
      </div>
      {hasActiveFilters && (
        <div className="mb-3">
          <button 
            onClick={clearAll}
            className="btn btn-link btn-sm text-decoration-none p-0"
          >
            Reset all
          </button>
        </div>
      )}
    </>
  );
};

export default Filters;
