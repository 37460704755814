import React from 'react'
import Button from 'react-bootstrap/Button'
import TextareaAutosize from 'react-textarea-autosize'
import { isEqual } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { createCompletion } from './actions'
import { getChannelState, isCreating, isProcessing } from './selectors'
import { ChannelState } from '../../types'
import ChannelStateDot from '../../components/ChannelStateDot'

const EMPTY_STRING = ""
const PLACE_HOLDER = "Ask your question here..."
const ENTER_KEYBOARD_EVENT = "Enter"

function Footer() {
  const dispatch = useAppDispatch()
  const creating = useAppSelector(isCreating, isEqual)
  const processing = useAppSelector(isProcessing, isEqual)
  const channelState = useAppSelector(getChannelState, isEqual)
  const [userRequest, setUserRequest] = React.useState(EMPTY_STRING)
  const [loading, setLoading] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)

  const handleSubmit = () => {
    if (!disabled) {
      dispatch(createCompletion({ userRequest }))
      setUserRequest(EMPTY_STRING)
    }
  }

  const handleKeyDown = (event: any) => {
    if (ENTER_KEYBOARD_EVENT === event.key) {
      event.preventDefault()
      handleSubmit()
    }
  }

  React.useEffect(() => {
    setLoading(creating || processing)
  }, [creating, processing])

  React.useEffect(() => {
    setDisabled(
      EMPTY_STRING === userRequest
      || creating
      || processing
      || ChannelState.Connected !== channelState
    )
  }, [userRequest, creating, processing, channelState])

  return (
    <div className="pilot-completions-footer">
      <div className="pilot-completions-footer__form">
        <TextareaAutosize
          autoFocus
          className="form-control"
          rows={1}
          maxRows={2}
          value={userRequest}
          onChange={(e) => setUserRequest(e.target.value)}
          placeholder={PLACE_HOLDER}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="pilot-completions-footer__actions">
        <ChannelStateDot channelState={channelState} />
        <Button
          variant="primary"
          onClick={() => handleSubmit()}
          size='sm'
          disabled={disabled}
        >
          <FontAwesomeIcon
            icon={loading ? faSpinner : faArrowUp}
            className={loading ? "fa-spin" : undefined}
          />
        </Button>
      </div>
    </div>
  )
}

export default Footer
