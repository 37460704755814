import React from 'react';
import ProgressCircle from '../shared/ProgressCircle';

const PaymentPlanSummary = ({ paymentPlan }) => {
  if (!paymentPlan) return null;

  const {
    total_amount_customer_pays,
    balance_remaining,
    total_savings,
    previous_payment_date,
    next_payment_amount,
    next_payment_date,
    payment_history,
    progress_percentage,
    last_payment_date,
    total_payments_count,
    payments_remaining,
    is_subscription_active,
    subscription_needs_attention,
    subscription_latest_invoice_url
  } = paymentPlan;

  return (
    <div className="border-0 mb-4">
      <h3 className="mb-4">Payment Plan</h3>

      {is_subscription_active && next_payment_amount && next_payment_date && (
        <>
          <div className="alert alert-success-muted d-flex align-items-center rounded-top-end-0 rounded-bottom-start-0 border border-success" role="alert">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              fill="currentColor"
              className="bi flex-shrink-0 me-2" 
              viewBox="0 0 16 16" 
              role="img" 
              aria-label="Success:"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
            <div className="text-body">
              {next_payment_amount} auto-pay scheduled for {next_payment_date}.
            </div>
          </div>

          <div>
            <div>
              <span>Upcoming payment: {next_payment_amount}</span>
            </div>
            <div>
              <span className="text-muted small">Due {next_payment_date}</span>
            </div>
          </div>
        </>
      )}

      {subscription_needs_attention && next_payment_amount && previous_payment_date && (
        <div className="alert alert-danger-muted d-flex align-items-center rounded-top-end-0 rounded-bottom-start-0 border border-danger" role="alert">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            fill="currentColor"
            className="bi flex-shrink-0 me-2" 
            viewBox="0 0 16 16" 
            role="img" 
            aria-label="Danger:"
          >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          <div className="text-body">
            Failed autopay of {next_payment_amount} on {previous_payment_date}. If you fail to make this payment, you will be in default. <a href={subscription_latest_invoice_url} target="_blank" rel="noopener noreferrer" className="text-decoration-underline text-primary">Click here to update your payment method.</a>
          </div>
        </div>
      )}

      <hr className="text-muted opacity-25 my-4" />

      {payment_history && payment_history.length > 0 && (
        <div>
          <h4 className="mb-4">Transactions</h4>
          {payment_history.map((payment) => (
            <div key={payment.id} className="d-flex justify-content-between mb-2">
              <span>{payment.amount} paid on {payment.date}</span>
            </div>
          ))}
          <hr className="text-muted opacity-25 my-4" />
        </div>
      )}
      
      <div>
        <h4 className="mb-4">Payment Plan Summary</h4>

        <div className="row mb-4 gy-4">
          <div className="col-md-8">
            <div className="d-flex justify-content-between mb-2">
              <span>Total payment amount:</span>
              <span>{total_amount_customer_pays}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>Balance remaining:</span>
              <span>{balance_remaining}</span>
            </div>
            {total_savings && (
              <div className="d-flex justify-content-between mb-2">
                <span>Total savings:</span>
                <span>{total_savings}</span>
              </div>
            )}
            <div className="d-flex justify-content-between mb-2">
              <span>Payments:</span>
              <span>{total_payments_count}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>Payments remaining:</span>
              <span>{payments_remaining}</span>
            </div>
            {is_subscription_active && (
              <div className="d-flex justify-content-between">
                <span>Last payment:</span>
                <span>{last_payment_date}</span>
              </div>
            )}
          </div>
          
          <div className="col-md-4 text-center">
            <ProgressCircle titleText="Paid" percentage={progress_percentage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlanSummary; 
