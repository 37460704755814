import React, { useState, useEffect } from 'react';

const SendPaymentDetails = ({ account, onSuccess }) => {
  const [formData, setFormData] = useState({
    file_number: '',
    payee: '',
    recipient: '',
    mailing_address: ''
  });
  const [formState, setFormState] = useState({
    isSubmitting: false,
    isSubmitted: false,
    error: null
  });
  const [activeDropdown, setActiveDropdown] = useState(false);
  
  // Add a unique row identifier
  const rowId = `payment-details-row-${account.customer_document.id}`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(`#${rowId} .action-dropdown`);
      const button = document.querySelector(`#${rowId} .action-button`);
      
      // Don't close if clicking on the button
      if (button?.contains(event.target)) {
        return;
      }
      
      // Close if clicking outside dropdown
      if (!dropdown?.contains(event.target)) {
        setActiveDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [rowId]);

  // Add this new function to handle dropdown visibility
  const ensureDropdownVisible = () => {
    const tableContainer = document.getElementById('table-container');
    const dropdown = document.querySelector(`#${rowId} .action-dropdown`);
    
    if (tableContainer && dropdown) {
      const dropdownRect = dropdown.getBoundingClientRect();
      const containerRect = tableContainer.getBoundingClientRect();
      
      // Calculate if dropdown extends beyond visible area
      const overflowAmount = (dropdownRect.bottom - containerRect.bottom) + 20; // 20px buffer
      
      if (overflowAmount > 0) {
        const scrollOptions = {
          top: tableContainer.scrollTop + overflowAmount,
          behavior: 'smooth'
        };
        tableContainer.scroll(scrollOptions);
      }
    }
  };

  const handleButtonClick = () => {
    setActiveDropdown(!activeDropdown);
    
    // Scroll when opening dropdown
    if (!activeDropdown) {
      const tableContainer = document.getElementById('table-container');
      if (tableContainer) {
        const scrollOptions = {
          left: tableContainer.scrollWidth,
          behavior: 'smooth'
        };
        setTimeout(() => {
          tableContainer.scroll(scrollOptions);
          ensureDropdownVisible();
        }, 0);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormState({ ...formState, isSubmitting: true, error: null });

    if (!confirm("Are you sure you want to submit?")) {
      setFormState({ ...formState, isSubmitting: false, isSubmitted: false });
      return;
    }
    
    try {
      const response = await fetch('/collectors/api/update_payment_details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          customer_document_id: account.customer_document.id,
          ...formData
        })
      });

      if (response.ok) {
        setFormState({ isSubmitting: false, isSubmitted: true, error: null });
        onSuccess();
      } else {
        const data = await response.json();
        alert(data.error);
        setFormState({ isSubmitting: false, isSubmitted: false });
      }
    } catch (e) {
      alert(e.message);
      setFormState({ isSubmitting: false, isSubmitted: false });
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="position-relative" id={rowId}>
      <div className="d-flex align-items-start">
        <span className="position-relative">
          <button
            type="button"
            className={`d-block w-100 border-0 px-3 py-2 text-start rounded-top-start-1 rounded-bottom-end-1 bg-warning text-white action-button`}
            style={{ whiteSpace: 'nowrap' }}
            onClick={handleButtonClick}
          >
            Enter Payment Details
          </button>

          {activeDropdown && (
            <div className="bg-white shadow-lg border rounded-start-top-1 rounded-end-bottom-1 p-3 position-absolute action-dropdown" 
                 style={{ 
                   top: '100%',
                   right: 0,
                   zIndex: 1000,
                   marginTop: 2,
                   minWidth: '300px'
                 }}>
              <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column gap-2">
                  <input
                    type="text"
                    name="file_number"
                    value={formData.file_number}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                    placeholder="File #"
                    required
                  />
                  <input
                    type="text"
                    name="payee"
                    value={formData.payee}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                    placeholder="Pay to"
                    required
                  />
                  <input
                    type="text"
                    name="recipient"
                    value={formData.recipient}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                    placeholder="Mail to"
                    required
                  />
                  <input
                    type="text"
                    name="mailing_address"
                    value={formData.mailing_address}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                    placeholder="Mailing Address"
                    required
                  />
                  <button 
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={formState.isSubmitting || formState.isSubmitted}
                  >
                    {formState.isSubmitting ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        Submitting...
                      </>
                    ) : formState.isSubmitted ? 'Submitted' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default SendPaymentDetails;