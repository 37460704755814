export const addQueryParams = (url: string, params: any) => {
  try {
    const urlObj = new URL(url);

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        urlObj.searchParams.set(key, params[key]);
      }
    }

    return urlObj.toString();
  } catch (error) {
    console.error("Invalid URL:", error);
    return url; // Return original URL if invalid
  }
}
