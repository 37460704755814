import { configureStore } from "@reduxjs/toolkit"
import { createLogger } from "redux-logger"

import { isNotProduction } from "./utils/env"
import rootReducer from "./reducers"

const store = configureStore({
  reducer: rootReducer,
  devTools: isNotProduction,
  middleware: (getDefaultMiddleware) => {
    if (isNotProduction) {
      return getDefaultMiddleware().concat(createLogger());
    } else {
      return getDefaultMiddleware();
    }
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
