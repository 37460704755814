import React from 'react'
import { isEqual } from 'lodash'

import { CompletionRole, CompletionState } from '../../types'
import Bubble from './Bubble'
import { useAppSelector } from '../../hooks';
import { getCompletionById } from './selectors';

type Props = {
  completionId: number;
}

function ListItem({ completionId }: Props) {
  const completion = useAppSelector((state) => getCompletionById(state, completionId), isEqual)

  return (
    <>
      <Bubble
        completionId={completionId}
        content={completion.userRequest}
        role={CompletionRole.User}
        state={CompletionState.Successful}
      />
      <Bubble
        completionId={completionId}
        content={completion.assistantResponse || ""}
        role={CompletionRole.Assistant}
        state={completion.state}
      />
    </>
  )
}

export default ListItem
